import {
  TaskStatus,
  PhoneNumberType,
  TaskStatusReason,
  AffiliationCallSessionInput,
  CreateAffiliationTaskSessionsInput
} from "Shared/graphql/generated";
import { TFromPhoneNumber, TVerificationUpdateData } from "Shared/types";

import { getStatusForCompleteVerification } from "./getStatusForCompleteVerification";

/**
 * Get variables (input data) ready to be send on back-end with completeVerificationTask mutation
 */
export const getVerifyTaskVariables = ({
  updateData: { notes, ...update },
  phoneNumbers,
  taskId,
  status
}: {
  updateData: TVerificationUpdateData;
  phoneNumbers: TFromPhoneNumber[] | undefined;
  taskId: string;
  status: TaskStatus;
}): CreateAffiliationTaskSessionsInput => {
  const taskSessions: AffiliationCallSessionInput[] =
    phoneNumbers
      ?.filter((phoneNumber) => phoneNumber.called)
      .map((phoneNumber) => {
        const {
          number,
          extension,
          callStatus,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle,
          type
        } = phoneNumber;
        const statusReason =
          getStatusForCompleteVerification(callStatus) ||
          TaskStatusReason.DeclinedVerification;

        return {
          phoneNumber: number,
          phoneExtension: extension,
          status,
          phoneType: type || PhoneNumberType.Unknown,
          notes,
          statusReason,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle,
          update
        };
      }) || [];

  const verifyTaskVariables: CreateAffiliationTaskSessionsInput = {
    verificationTaskId: taskId,
    taskSessions
  };

  return verifyTaskVariables;
};
