// TODO: think about return type, currently it's Record<string, unknown>
// This function will convert empty string to null or undefined based on previous value
// If previous value was undefined, empty string will be converted to undefined
// If previous value was defined, empty string will be converted to nul
// If current value is not empty string, the value will be returned without any affects

export const convertEmptyStringToNullOrUndefined = <
  T extends Record<string, unknown>
>(
  newObject: T,
  prevObject: T
): Partial<T> => {
  return Object.entries(newObject).reduce((acc: Partial<T>, [key, value]) => {
    if (value === "") {
      if (prevObject[key] === undefined) {
        acc[key as keyof T] = undefined;
      } else {
        acc[key as keyof T] = null as unknown as T[keyof T];
      }
    } else {
      acc[key as keyof T] = value as T[keyof T];
    }
    return acc;
  }, {});
};
