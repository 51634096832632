import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useCallback } from "react";

import { GeocodedAddress } from "Shared/graphql/generated";

import {
  AddStates,
  FormikTextField,
  FormikAutocompleteAddress,
  TAddressOption,
  useDebouncedGeocode,
  useIsAutocompletedAddressFeatureEnabled
} from "Shared";

const transformAddressData = (items: GeocodedAddress[]): TAddressOption[] =>
  items.map((item) => ({
    label: item.label,
    value: item.label,
    formData: item
  }));

export const FormikAdressGroup: React.FC = () => {
  const isAutocompletedAddressFeatureEnabled =
    useIsAutocompletedAddressFeatureEnabled();

  const { setFieldValue, values } =
    useFormikContext<{ orgAddressLine1: string }>();
  const fieldValue = values.orgAddressLine1;

  const { data } = useDebouncedGeocode(fieldValue);

  const onAddressSelect = useCallback(
    (option: TAddressOption) => {
      const address = option?.formData;

      if (address) {
        setFieldValue("orgAddressLine1", address.addressLine1);
        setFieldValue("orgAddressRegion", address.stateCode);
        setFieldValue("orgAddressLocality", address.city);
        setFieldValue("orgAddressPostalCode", address.postalCode);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <Grid item xs={12}>
        {isAutocompletedAddressFeatureEnabled ? (
          <FormikAutocompleteAddress
            name="orgAddressLine1"
            label="Address Line 1"
            options={transformAddressData(
              data?.autocompleteAddress.addresses || []
            )}
            onAddressSelect={onAddressSelect}
          />
        ) : (
          <FormikTextField
            name="orgAddressLine1"
            label="Address Line 1"
            shrink
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name="orgAddressLine2"
          label="Address Line 2 (Suite, Floor, etc.)"
          shrink
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField name="orgAddressLocality" label="City" />
      </Grid>
      <Grid item xs={4}>
        <AddStates name="orgAddressRegion" />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField name="orgAddressPostalCode" label="Zip Code" />
      </Grid>
    </>
  );
};
