import { MouseEvent } from "react";
import { useFormikContext } from "formik";
import { Button } from "@material-ui/core";

import {
  useOrgDataContext,
  useVerifyCallContext
} from "OrganizationValidation/context";
import { THCPRowValues, transformTaskValuesToCreateVariables } from "Shared";
import { useVerifyHCPTask } from "OrganizationValidation/hooks";
import { TaskStatus, VerificationTaskFragment } from "Shared/graphql/generated";

export type VerifyButtonProps = {
  taskId: string;
  hcpName: string;
  onSuccessfulSubmit?: (task: VerificationTaskFragment) => void;
};

// we need to disable button if form invalid or agent don't selected valid call status
export const VerifyButton = ({
  taskId,
  hcpName,
  onSuccessfulSubmit
}: VerifyButtonProps) => {
  const {
    isValid,
    isSubmitting,
    initialValues: initialFormValues,
    values: formValues,
    setSubmitting
  } = useFormikContext<THCPRowValues>();

  const { orgData } = useOrgDataContext();
  // isVerifyCallSelected - is selected valid phone number call status
  const { isVerifyCallSelected, phoneNumbers } = useVerifyCallContext();

  const { createAffiliationTask } = useVerifyHCPTask(hcpName);

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setSubmitting(true);

    const variables = transformTaskValuesToCreateVariables({
      taskId,
      newFormValues: {
        ...formValues,
        ...orgData,
        phoneNumbers,
        // we don't use autocomplete address so it will be always false
        isAutocompleted: false
      },
      initFormValues: initialFormValues,
      status: TaskStatus.Complete
    });

    const task = await createAffiliationTask({ variables });

    if (onSuccessfulSubmit && task) {
      onSuccessfulSubmit(task);
    }

    setSubmitting(false);
  };

  // if form is invalid - disabled
  // if selected invalid call status - disabled
  // if form is submitting - disabled
  const isDisabled = !isValid || !isVerifyCallSelected || isSubmitting;

  return (
    <Button
      variant="contained"
      onClick={handleSubmit}
      fullWidth
      color="primary"
      type="submit"
      disabled={isDisabled}
    >
      Verify
    </Button>
  );
};
