import { Paper, Grid, Box, Typography } from "@material-ui/core";

import logo from "Shared/theme/logo.png";

type PaperWithLogoProps = React.FC<{
  label: string;
}>;

export const PaperWithLogo: PaperWithLogoProps = ({
  label,
  children
}): JSX.Element => {
  return (
    <Box maxWidth={560} p={4}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={3}
            >
              <img src={logo} height={40} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              {label}
            </Typography>
          </Grid>
        </Grid>
        <Box pb={5}>{children}</Box>
      </Paper>
    </Box>
  );
};
