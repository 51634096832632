import {
  DID_NOT_CALL,
  TFromPhoneNumber,
  TOrgVerificationInitialValues,
  transformInitialValuesTOHoursOfOperation
} from "Shared";
import {
  CreateOrganizationTaskSessionMutationVariables,
  PhoneNumberType,
  TaskStatus,
  TaskStatusReason
} from "Shared/graphql/generated";

export const canSaveAndDefer = (phoneNumbers: TFromPhoneNumber[]) => {
  return phoneNumbers.some(({ callStatus }) => callStatus !== DID_NOT_CALL);
};

export const canComplete = (phoneNumbers: TFromPhoneNumber[]) => {
  return phoneNumbers.some(({ callStatus }) => callStatus !== DID_NOT_CALL);
};

export const getOrgValidationVariables = ({
  formData,
  verificationTaskId,
  status,
  isAutocompleted
}: {
  formData: Partial<TOrgVerificationInitialValues>;
  verificationTaskId: string;
  status: TaskStatus;
  isAutocompleted: boolean;
}) => {
  const {
    phoneNumbers,
    notes,
    hoursOfOperationOrganization,
    ...restFormValues
  } = formData;

  const verifiedPhones = phoneNumbers?.filter(
    ({ callStatus }) => callStatus !== DID_NOT_CALL
  );

  const operationHours = transformInitialValuesTOHoursOfOperation(
    [],
    hoursOfOperationOrganization
  );

  if (verifiedPhones?.length) {
    const variables: CreateOrganizationTaskSessionMutationVariables = {
      verificationTaskId,
      taskSessions: verifiedPhones.map(
        ({
          callStatus,
          number,
          extension,
          type,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle
        }) => ({
          status,
          notes: notes || undefined,
          statusReason: callStatus as TaskStatusReason,
          phoneNumber: number,
          phoneExtension: extension,
          phoneType: type || PhoneNumberType.Unknown,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle,
          update: {
            ...restFormValues,
            operationHours,
            isAutocompleted
          }
        })
      )
    };

    return variables;
  }
};
