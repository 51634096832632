import isEqual from "lodash/isEqual";

export const findDifference = <
  T extends Record<string, unknown>,
  N extends Partial<T> = T
>(
  oldValues: T,
  newValues: N
): Partial<T> => {
  return Object.entries(newValues).reduce(
    (accumulator: Partial<T>, [key, value]) => {
      if (!isEqual(oldValues[key], value)) {
        accumulator[key as keyof T] = value;
      }
      return accumulator;
    },
    {}
  );
};
