import omit from "lodash/omit";

import { defaultFormFilledParams, listExcludedFormFields } from "Shared";
import { getDefaultTransformedFormData, prepareFormData } from "Shared/utils";
import {
  TFromPhoneNumber,
  TValidateTaskFromInitialValues,
  TVerificationUpdateData
} from "Shared/types";

import { transformFormDataToVerificationData } from "./transformFormDataToVerificationData";

/**
 * This function prepare data to send it on back-end
 * @param newFormValues - current vales in form
 * @param initFormValues - initial values
 * @returns - updateData: TVerificationUpdateData and phoneNumbers?: TFromPhoneNumber[]
 */
export const getUpdateData = (
  newFormValues: TValidateTaskFromInitialValues,
  initFormValues: TValidateTaskFromInitialValues
): {
  updateData: TVerificationUpdateData;
  phoneNumbers?: TFromPhoneNumber[];
} => {
  const changedData = prepareFormData(
    omit(newFormValues, listExcludedFormFields),
    initFormValues
  );

  changedData.phoneNumbers = newFormValues.phoneNumbers;

  // separate phone numbers array from of the rest changed from data
  const { phoneNumbers, ...restChangedValues } = changedData;

  // add data that was filled by frontend by default
  const excludedInitialValues = getDefaultTransformedFormData(
    changedData,
    defaultFormFilledParams
  );

  // transform specific form fields (like faxNumber and operationHour) for back-end
  const updateData = transformFormDataToVerificationData({
    ...restChangedValues,
    ...excludedInitialValues
  });

  if (!updateData.affiliationStatus) {
    updateData.affiliationStatus = newFormValues.affiliationStatus;
  }

  // we don't have this filed in form
  const affiliationId = initFormValues.affiliationId;
  if (affiliationId) {
    updateData.affiliationId = affiliationId;
  }

  return {
    updateData: updateData,
    phoneNumbers
  };
};
