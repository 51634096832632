import { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";

import {
  TOrgVerificationInitialValues,
  TFormUpdateModalModes,
  onModalConfirmAfterValidation,
  SubmitAddressOption,
  ConfirmAlertPopup,
  AddressValidationPopup,
  TValidationUpdateParams,
  getUpdateFormDataActionByModalMode,
  useModal,
  useAddressValidationBeforeSubmit
} from "Shared";
import { usePreventNavigationPopupContext } from "OrganizationValidation/context";

import { OrganizationActionsButtons } from "./OrganizationActionsButtons";

type SaveButtonProps = {
  isCompleted: boolean;
  isUpdateButtonEnabled: boolean;
  isSaveAndDeferEnabled: boolean;
  isCompleteButtonEnabled: boolean;
  selectedSubmitAddressOption: SubmitAddressOption | null;
  setSelectedSubmitAddressOption: (option: SubmitAddressOption | null) => void;
  onComplete: () => Promise<void>;
  onSaveAndDefer: () => Promise<void>;
  onUpdate: () => Promise<void>;
};

export const OrganizationActionsContainer = ({
  isCompleted,
  isUpdateButtonEnabled,
  isSaveAndDeferEnabled,
  isCompleteButtonEnabled,
  selectedSubmitAddressOption,
  setSelectedSubmitAddressOption,
  onUpdate,
  onComplete,
  onSaveAndDefer
}: SaveButtonProps) => {
  const {
    values: formValues,
    setValues: updateFormState,
    dirty
  } = useFormikContext<TOrgVerificationInitialValues>();

  const { setIsOrgFormIsDirty } = usePreventNavigationPopupContext();

  const {
    isOpen: isConfirmModalOpen,
    closeModal: closeConfirmModal,
    openModal: openConfirmModal
  } = useModal();

  const {
    validationAddressData,
    formUpdateModalMode,
    closeModalValidation,
    isOpenValidation,
    onOpenModalComplete,
    onOpenModalSaveAndDefer,
    onOpenModalUpdate
  } = useAddressValidationBeforeSubmit({
    onUpdate,
    onComplete: openConfirmModal,
    onSaveAndDefer
  });

  useEffect(() => {
    if (dirty) {
      setIsOrgFormIsDirty(true);
    }
  }, [dirty, setIsOrgFormIsDirty]);

  const updatedFormData: TValidationUpdateParams<TOrgVerificationInitialValues> =
    useMemo(() => {
      return {
        validationAddressData,
        formValues,
        updateForm: getUpdateFormDataActionByModalMode({
          formUpdateModalMode,
          onComplete,
          onUpdate,
          onSaveAndDefer
        }),
        updateFormState
      };
    }, [
      formUpdateModalMode,
      formValues,
      onComplete,
      onSaveAndDefer,
      onUpdate,
      updateFormState,
      validationAddressData
    ]);

  const onValidateConfirm = async () => {
    if (formUpdateModalMode === TFormUpdateModalModes.COMPLETE) {
      closeModalValidation();
      openConfirmModal();
    } else {
      await onModalConfirmAfterValidation(
        updatedFormData,
        selectedSubmitAddressOption
      );
      closeModalValidation();
    }
  };

  const onCompleteModalConfirm = async () => {
    await onModalConfirmAfterValidation(
      updatedFormData,
      selectedSubmitAddressOption
    );
    closeConfirmModal();
  };

  return (
    <Box>
      <ConfirmAlertPopup
        isOpen={isConfirmModalOpen}
        onConfirm={onCompleteModalConfirm}
        onCancel={closeConfirmModal}
      />
      <AddressValidationPopup
        isOpen={isOpenValidation}
        recommendedAddress={validationAddressData?.label}
        submittedAddress={formValues}
        selectedSubmitAddressOption={selectedSubmitAddressOption}
        formUpdateModalMode={formUpdateModalMode}
        onConfirm={onValidateConfirm}
        onCancel={closeModalValidation}
        setSelectedSubmitAddressOption={setSelectedSubmitAddressOption}
      />
      <OrganizationActionsButtons
        isCompleted={isCompleted}
        isUpdateButtonEnabled={isUpdateButtonEnabled}
        isSaveAndDeferEnabled={isSaveAndDeferEnabled}
        isCompleteButtonEnabled={isCompleteButtonEnabled}
        onUpdate={onOpenModalUpdate}
        onSaveAndDefer={onOpenModalSaveAndDefer}
        onComplete={onOpenModalComplete}
      />
    </Box>
  );
};
