import { useFormikContext } from "formik";
import { useCallback } from "react";

import {
  transformTaskValuesToCreateVariables,
  transformTaskValuesToUpdateVariables,
  TValidateTaskFromInitialValues
} from "Shared";

import {
  CreateAffiliationTaskSessionsInput,
  TaskStatus
} from "Shared/graphql/generated";
import { canComplete, canSaveAndDefer } from "OrganizationValidation/helpers";

import { useCreateAffiliationTaskSessions } from "./useCreateAffiliationTaskSessions";
import { useUpdateAffiliationTaskSession } from "./useUpdateAffiliationTaskSessions";

export type UseAffiliationActionsProps = {
  verificationTaskId: string;
  sessionId?: string;
  isAutocompleted: boolean;
};

export const useAffiliationActions = ({
  verificationTaskId,
  sessionId,
  isAutocompleted
}: UseAffiliationActionsProps) => {
  const { isValid, dirty, values, initialValues } =
    useFormikContext<TValidateTaskFromInitialValues>();

  const { createAffiliationTask, isLoading: isCompleting } =
    useCreateAffiliationTaskSessions();

  const { updateAffiliationTask, isLoading: isUpdating } =
    useUpdateAffiliationTaskSession();

  const isSaveAndDeferEnabled =
    !isCompleting &&
    dirty &&
    isValid &&
    values.phoneNumbers &&
    canSaveAndDefer(values.phoneNumbers);
  const isCompleteButtonEnabled =
    !isCompleting &&
    isValid &&
    values.phoneNumbers &&
    canComplete(values.phoneNumbers);
  const isUpdateButtonEnabled =
    dirty && isValid && !isUpdating && !isCompleting;

  const onSaveAndDefer = useCallback(
    async (passedValues?: Partial<TValidateTaskFromInitialValues>) => {
      const variables: CreateAffiliationTaskSessionsInput =
        transformTaskValuesToCreateVariables({
          taskId: verificationTaskId,
          newFormValues: { ...(passedValues || values), isAutocompleted },
          initFormValues: initialValues,
          status: TaskStatus.Deferred
        });

      if (variables) {
        await createAffiliationTask({
          variables,
          isComplete: false
        });
      }
    },
    [
      createAffiliationTask,
      verificationTaskId,
      initialValues,
      values,
      isAutocompleted
    ]
  );

  const onComplete = useCallback(
    async (passedValues?: Partial<TValidateTaskFromInitialValues>) => {
      const variables = transformTaskValuesToCreateVariables({
        taskId: verificationTaskId,
        newFormValues: { ...(passedValues || values), isAutocompleted },
        initFormValues: initialValues,
        status: TaskStatus.Complete
      });

      if (variables) {
        await createAffiliationTask({
          variables,
          isComplete: true
        });
      }
    },
    [
      createAffiliationTask,
      verificationTaskId,
      initialValues,
      values,
      isAutocompleted
    ]
  );

  const onUpdate = useCallback(
    async (passedValues?: Partial<TValidateTaskFromInitialValues>) => {
      if (sessionId) {
        const variables = transformTaskValuesToUpdateVariables({
          sessionId: sessionId,
          newFormValues: { ...(passedValues || values), isAutocompleted },
          initFormValues: initialValues,
          isAutocompleted
        });

        await updateAffiliationTask(variables);
      }
    },
    [sessionId, values, initialValues, isAutocompleted, updateAffiliationTask]
  );

  return {
    onComplete,
    onSaveAndDefer,
    onUpdate,
    isSaveAndDeferEnabled,
    isCompleteButtonEnabled,
    isUpdateButtonEnabled,
    isLoading: isCompleting || isUpdating
  };
};
