import { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";

import { useNewAffiliationContext } from "AffiliationValidation/context";
import {
  AddressValidationPopup,
  ConfirmAlertPopup,
  getUpdateFormDataActionByModalMode,
  onModalConfirmAfterValidation,
  TFormUpdateModalModes,
  TValidateTaskFromInitialValues,
  TValidationUpdateParams,
  useModal,
  SubmitAddressOption,
  useAddressValidationBeforeSubmit
} from "Shared";

import { TopSectionActionButtons } from "./TopSectionActionButtons";

export type TopSectionActionProps = {
  readonly: boolean;
  completed: boolean;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  isSaveAndDeferDisabled: boolean;
  onSaveAndDefer: () => Promise<void>;
  onComplete: () => Promise<void>;
  onUpdate: () => Promise<void>;
  selectedSubmitAddressOption: SubmitAddressOption | null;
  setSelectedSubmitAddressOption: (value: SubmitAddressOption | null) => void;
};

export const TopSectionAction: React.FC<TopSectionActionProps> = ({
  readonly,
  isSubmitDisabled,
  isSaveAndDeferDisabled,
  completed,
  onSaveAndDefer,
  onComplete,
  onUpdate,
  selectedSubmitAddressOption,
  setSelectedSubmitAddressOption
}) => {
  const {
    values: formValues,
    setValues: updateFormState,
    dirty
  } = useFormikContext<TValidateTaskFromInitialValues>();

  const {
    isOpen: isConfirmModalOpen,
    closeModal: closeConfirmModal,
    openModal: openConfirmModal
  } = useModal();

  const { setIsDirty } = useNewAffiliationContext();
  const {
    validationAddressData,
    formUpdateModalMode,
    closeModalValidation,
    isOpenValidation,
    onOpenModalComplete,
    onOpenModalSaveAndDefer,
    onOpenModalUpdate
  } = useAddressValidationBeforeSubmit({
    onUpdate,
    onComplete: openConfirmModal,
    onSaveAndDefer
  });

  useEffect(() => {
    if (readonly) {
      setIsDirty(false);
    } else {
      setIsDirty(dirty);
    }
  }, [dirty, setIsDirty, readonly]);

  const updatedFormData: TValidationUpdateParams<TValidateTaskFromInitialValues> =
    useMemo(() => {
      return {
        validationAddressData,
        formValues,
        updateForm: getUpdateFormDataActionByModalMode({
          formUpdateModalMode,
          onComplete,
          onUpdate,
          onSaveAndDefer
        }),
        updateFormState
      };
    }, [
      formUpdateModalMode,
      formValues,
      onComplete,
      onSaveAndDefer,
      onUpdate,
      updateFormState,
      validationAddressData
    ]);

  const onCompleteModalConfirm = async () => {
    await onModalConfirmAfterValidation(
      updatedFormData,
      selectedSubmitAddressOption
    );
    closeConfirmModal();
  };

  const onValidateConfirm = async () => {
    if (formUpdateModalMode === TFormUpdateModalModes.COMPLETE) {
      closeModalValidation();
      openConfirmModal();
    } else {
      await onModalConfirmAfterValidation(
        updatedFormData,
        selectedSubmitAddressOption
      );
      closeModalValidation();
    }
  };

  return (
    <>
      <ConfirmAlertPopup
        isOpen={isConfirmModalOpen}
        onConfirm={onCompleteModalConfirm}
        onCancel={closeConfirmModal}
      />
      <AddressValidationPopup
        isOpen={isOpenValidation}
        recommendedAddress={validationAddressData?.label}
        submittedAddress={formValues}
        selectedSubmitAddressOption={selectedSubmitAddressOption}
        formUpdateModalMode={formUpdateModalMode}
        onConfirm={onValidateConfirm}
        onCancel={closeModalValidation}
        setSelectedSubmitAddressOption={setSelectedSubmitAddressOption}
      />

      <TopSectionActionButtons
        isSubmitDisabled={isSubmitDisabled}
        isSaveAndDeferDisabled={isSaveAndDeferDisabled}
        completed={completed}
        onUpdate={onOpenModalUpdate}
        onComplete={onOpenModalComplete}
        onSaveAndDefer={onOpenModalSaveAndDefer}
      />
    </>
  );
};
