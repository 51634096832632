import { SubmitAddressOption, TUseValidationAddressState } from "Shared";

export enum TFormUpdateModalModes {
  UPDATE = "Update",
  COMPLETE = "Complete",
  SAVE_AND_DEFER = "Save and defer"
}

const prepareAddressData = (
  validationAddressData: TUseValidationAddressState
) => ({
  orgAddressLine1: `${validationAddressData?.houseNumber || ""} ${
    validationAddressData?.street || ""
  }`,
  orgAddressLocality: validationAddressData?.city,
  orgAddressPostalCode: validationAddressData?.postalCode,
  orgAddressRegion: validationAddressData?.stateCode
});

export type TValidationUpdateParams<TFormData> = {
  validationAddressData: TUseValidationAddressState;
  formValues: TFormData;
  updateForm: (formValues?: TFormData) => Promise<void>;
  updateFormState: (values: TFormData) => void;
};

export const getUpdateFormDataActionByModalMode = ({
  formUpdateModalMode,
  onSaveAndDefer,
  onUpdate,
  onComplete
}: {
  formUpdateModalMode: TFormUpdateModalModes | null;
  onSaveAndDefer: () => Promise<void>;
  onUpdate: () => Promise<void>;
  onComplete: () => Promise<void>;
}) => {
  let updateForm = async () => {};

  if (formUpdateModalMode === TFormUpdateModalModes.SAVE_AND_DEFER) {
    updateForm = onSaveAndDefer;
  }

  if (formUpdateModalMode === TFormUpdateModalModes.UPDATE) {
    updateForm = onUpdate;
  }
  if (formUpdateModalMode === TFormUpdateModalModes.COMPLETE) {
    updateForm = onComplete;
  }
  return updateForm;
};

/**
 * This is a high order function that update form state before sending
 * updated form data to backend
 */
export const onUpdateFormAfterValidation = async <TFormData>({
  validationAddressData,
  formValues,
  updateForm,
  updateFormState
}: TValidationUpdateParams<TFormData>) => {
  const addressData = prepareAddressData(validationAddressData);

  const updatedFormValues = {
    ...formValues,
    ...addressData
  };

  await updateForm(updatedFormValues);
  updateFormState(updatedFormValues);
};

export const onModalConfirmAfterValidation = async <TFormData>(
  data: TValidationUpdateParams<TFormData>,
  selectedSubmitAddressOption: SubmitAddressOption | null
) => {
  const { validationAddressData, formValues, updateForm } = data;
  //This will use validation data if it is present
  if (
    validationAddressData &&
    selectedSubmitAddressOption !== SubmitAddressOption.SUBMITTED
  ) {
    await onUpdateFormAfterValidation(data);
  } else {
    await updateForm(formValues);
  }
};
