import {
  Modal,
  TFormUpdateModalModes,
  TValidateTaskFromInitialValues
} from "Shared";
import { AlertButtonType } from "Shared/types";

import { AlertPopup } from "../AlertPopup";

import { AddressValidationPopupDescription } from "./index";

export enum SubmitAddressOption {
  RECOMMENDED = "Recommended",
  SUBMITTED = "submitted"
}

const MODAL_TITLE = "Please check your address format:";
const CONFIRM_TEXT = "Next";
const BACK_TEXT = "Back";

type AddressValidationPopupProps = {
  isOpen: boolean;
  formUpdateModalMode: TFormUpdateModalModes | null;
  submittedAddress?: TValidateTaskFromInitialValues;
  recommendedAddress?: string;
  selectedSubmitAddressOption: SubmitAddressOption | null;
  onCancel: () => void;
  onConfirm: () => void;
  setSelectedSubmitAddressOption: (choice: SubmitAddressOption) => void;
};

export const AddressValidationPopup = ({
  isOpen,
  submittedAddress,
  formUpdateModalMode,
  selectedSubmitAddressOption,
  recommendedAddress = "",
  onCancel,
  onConfirm,
  setSelectedSubmitAddressOption
}: AddressValidationPopupProps): JSX.Element => {
  const validationConfirmButtonText =
    formUpdateModalMode === TFormUpdateModalModes.COMPLETE
      ? "Next"
      : formUpdateModalMode;

  return (
    <Modal isOpen={isOpen} onClose={onCancel} title={MODAL_TITLE}>
      <AlertPopup
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmButtonText={validationConfirmButtonText || CONFIRM_TEXT}
        cancelButtonText={BACK_TEXT}
        confirmButtonDisabled={!selectedSubmitAddressOption}
        description={
          <AddressValidationPopupDescription
            selectedSubmitAddressOption={selectedSubmitAddressOption}
            submittedAddress={submittedAddress}
            recommendedAddress={recommendedAddress}
            setSelectedSubmitAddressOption={setSelectedSubmitAddressOption}
          />
        }
        confirmButtonType={AlertButtonType.BLUE}
      />
    </Modal>
  );
};
