import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { generateRoutePath, NestedRouteName, RouteName } from "App";

export const usePathTaskHandler = () => {
  const navigate = useNavigate();

  const pathTaskHandler = useCallback(
    ({ npi, taskId }: { npi: string; taskId?: string }) => {
      if (taskId) {
        navigate(
          generateRoutePath({
            name: [RouteName.AffiliationValidation, NestedRouteName.Task],
            params: { npi, taskId }
          })
        );
      } else {
        navigate(
          generateRoutePath({
            name: RouteName.AffiliationValidation,
            params: { npi }
          })
        );
      }
    },
    [navigate]
  );

  return {
    pathTaskHandler
  };
};
