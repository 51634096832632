import { Box } from "@material-ui/core";
import { useState } from "react";

import { useAffiliationActions } from "AffiliationValidation/hooks";
import {
  StatusInfo,
  SubmitAddressOption,
  useIsAutocompletedAddressFeatureEnabled
} from "Shared";
import {
  CallTaskSessionFragment,
  UserFragment
} from "Shared/graphql/generated";

import { TopSectionAction } from "./TopSectionAction";

type TopSectionSubmitProps = {
  readonly: boolean;
  completed: boolean;
  taskId: string;
  sessionId?: string;
  completedBy?: UserFragment | null;
  lastUpdate?: CallTaskSessionFragment["lastUpdate"] | null;
  updated?: string;
};

export const TopSectionSubmit = ({
  readonly,
  completed,
  lastUpdate,
  completedBy,
  updated,
  sessionId,
  taskId
}: TopSectionSubmitProps) => {
  const [selectedSubmitAddressOption, setSelectedSubmitAddressOption] =
    useState<SubmitAddressOption | null>(null);
  const isAutocompletedAddressFeatureEnabled =
    useIsAutocompletedAddressFeatureEnabled();

  const isAutocompleted =
    isAutocompletedAddressFeatureEnabled &&
    selectedSubmitAddressOption !== SubmitAddressOption.SUBMITTED;

  const {
    isCompleteButtonEnabled,
    isSaveAndDeferEnabled,
    isUpdateButtonEnabled,
    isLoading,
    onComplete,
    onSaveAndDefer,
    onUpdate
  } = useAffiliationActions({
    verificationTaskId: taskId,
    sessionId,
    isAutocompleted
  });

  const isSubmitDisabled = completed
    ? !isUpdateButtonEnabled
    : !isCompleteButtonEnabled;

  return (
    <Box>
      <Box marginBottom={2}>
        <TopSectionAction
          isLoading={isLoading}
          isSubmitDisabled={isSubmitDisabled}
          isSaveAndDeferDisabled={!isSaveAndDeferEnabled}
          onSaveAndDefer={onSaveAndDefer}
          onComplete={onComplete}
          onUpdate={onUpdate}
          selectedSubmitAddressOption={selectedSubmitAddressOption}
          setSelectedSubmitAddressOption={setSelectedSubmitAddressOption}
          completed={completed}
          readonly={readonly}
        />
      </Box>
      {lastUpdate && (
        <StatusInfo
          lastUpdatedBy={lastUpdate.createdBy?.email}
          lastUpdatedOn={lastUpdate.created}
          completedBy={completedBy?.email}
          completedOn={updated}
        />
      )}
    </Box>
  );
};
