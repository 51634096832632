import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isoCode?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  line3?: Maybe<Scalars["String"]>;
  phoneExtension?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateCode?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Affiliation = {
  __typename?: "Affiliation";
  active: Scalars["Boolean"];
  affiliationType?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  deleted: Scalars["Boolean"];
  department?: Maybe<Scalars["String"]>;
  departmentRole?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  personInstitution: PersonInstitution;
  startDate?: Maybe<Scalars["DateTime"]>;
  title?: Maybe<Scalars["String"]>;
};

export type AffiliationCallSessionInput = {
  notes?: InputMaybe<Scalars["String"]>;
  phoneExtension?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  phoneType: PhoneNumberType;
  respondentDepartment?: InputMaybe<Scalars["String"]>;
  respondentFirstName?: InputMaybe<Scalars["String"]>;
  respondentLastName?: InputMaybe<Scalars["String"]>;
  respondentTitle?: InputMaybe<Scalars["String"]>;
  status: TaskStatus;
  statusReason: TaskStatusReason;
  update: AffiliationSessionUpdateInput;
};

export type AffiliationDataInput = {
  department: Scalars["String"];
  departmentRole: Scalars["String"];
  organization: OrganizationDataInput;
  person: PersonDataInput;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  status: AffiliationStatus;
};

export type AffiliationSessionUpdateInput = {
  affiliationId?: InputMaybe<Scalars["String"]>;
  affiliationStatus?: InputMaybe<AffiliationStatus>;
  appointmentPhoneNumber?: InputMaybe<Scalars["String"]>;
  appointmentPhoneNumberExt?: InputMaybe<Scalars["String"]>;
  complianceStatus?: InputMaybe<ComplianceStatus>;
  department?: InputMaybe<Scalars["String"]>;
  departmentRole?: InputMaybe<Scalars["String"]>;
  directFaxNumber?: InputMaybe<Scalars["String"]>;
  directPhoneNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  isAcceptNewPatients?: InputMaybe<Scalars["Boolean"]>;
  isAutocompleted?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  listingUrl?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  operationHours?: InputMaybe<Array<OperationHoursInput>>;
  orgAddressLine1?: InputMaybe<Scalars["String"]>;
  orgAddressLine2?: InputMaybe<Scalars["String"]>;
  orgAddressLocality?: InputMaybe<Scalars["String"]>;
  orgAddressPostalCode?: InputMaybe<Scalars["String"]>;
  orgAddressRegion?: InputMaybe<Scalars["String"]>;
  orgFaxNumber?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
  orgNpi?: InputMaybe<Scalars["String"]>;
  orgTaxId?: InputMaybe<Scalars["String"]>;
  personalPhoneNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  profileUrl?: InputMaybe<Scalars["String"]>;
  spokenLanguages?: InputMaybe<Array<Scalars["String"]>>;
};

export enum AffiliationStatus {
  Active = "ACTIVE",
  NeverAffiliated = "NEVER_AFFILIATED",
  PriorWork = "PRIOR_WORK",
  Unknown = "UNKNOWN"
}

export type AffiliationTaskData = {
  __typename?: "AffiliationTaskData";
  active: Scalars["Boolean"];
  address?: Maybe<TaskAddress>;
  department?: Maybe<Scalars["String"]>;
  departmentRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  institution: OrganizationTaskData;
  person: PersonTaskData;
  phone?: Maybe<PhoneNumber>;
};

export type AffiliationTaskInput = {
  initialData: AffiliationDataInput;
  orgId: Scalars["String"];
  personNpi: Scalars["String"];
};

export type Agency = {
  __typename?: "Agency";
  created: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  domain?: Maybe<Scalars["String"]>;
  domains?: Maybe<Array<Scalars["String"]>>;
  id: Scalars["String"];
  name: Scalars["String"];
  updated: Scalars["DateTime"];
  users?: Maybe<Array<User>>;
};

export type AgencyInput = {
  domains: Array<Scalars["String"]>;
  name: Scalars["String"];
};

export type AgencyMutationResponse = {
  __typename?: "AgencyMutationResponse";
  agency?: Maybe<Agency>;
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export type AutocompleteAddressInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  resultType?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type Call = {
  __typename?: "Call";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  phoneExtension: Scalars["String"];
  phoneNumber: Scalars["String"];
  phoneType?: Maybe<PhoneNumberType>;
  status?: Maybe<CallStatus>;
  updated: Scalars["DateTime"];
  userId: Scalars["String"];
};

export type CallSessionMutationResponse = {
  __typename?: "CallSessionMutationResponse";
  callSession?: Maybe<CallTaskSession>;
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export enum CallStatus {
  DeclinedVerification = "DECLINED_VERIFICATION",
  DoNotCall = "DO_NOT_CALL",
  NoAnswer = "NO_ANSWER",
  VerifiedCall = "VERIFIED_CALL",
  VerifyThroughFax = "VERIFY_THROUGH_FAX",
  WrongNumber = "WRONG_NUMBER"
}

export type CallTaskSession = {
  __typename?: "CallTaskSession";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdate?: Maybe<SessionUpdate>;
  notes?: Maybe<Scalars["String"]>;
  respondentDepartment?: Maybe<Scalars["String"]>;
  respondentFirstName?: Maybe<Scalars["String"]>;
  respondentLastName?: Maybe<Scalars["String"]>;
  respondentTitle?: Maybe<Scalars["String"]>;
  sessionUpdates: Array<SessionUpdate>;
  sourcePhoneExtension: Scalars["String"];
  sourcePhoneNumber: Scalars["String"];
  sourcePhoneType: Scalars["String"];
  status: Scalars["String"];
  statusReason: Scalars["String"];
  updated: Scalars["DateTime"];
};

export type CompleteVerificationTaskInput = {
  calls: Array<CreateVerificationCallInput>;
  id: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  taskType: TaskType;
};

export enum ComplianceStatus {
  Deceased = "DECEASED",
  Inactive = "INACTIVE",
  InJail = "IN_JAIL",
  Retired = "RETIRED",
  Suspended = "SUSPENDED"
}

export type CreateAffiliationTaskSessionsInput = {
  taskSessions: Array<AffiliationCallSessionInput>;
  verificationTaskId: Scalars["String"];
};

export type CreateOrganizationTaskSessionInput = {
  taskSessions: Array<OrganizationCallSessionInput>;
  verificationTaskId: Scalars["String"];
};

export type CreateVerificationCallInput = {
  phoneExtension?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  phoneType?: InputMaybe<PhoneNumberType>;
  status?: InputMaybe<TaskStatusReason>;
  verificationUpdates?: InputMaybe<Array<CreateVerificationUpdateInput>>;
};

export type CreateVerificationUpdateInput = {
  affiliationId?: InputMaybe<Scalars["String"]>;
  affiliationStatus: AffiliationStatus;
  affiliationVerificationUrl?: InputMaybe<Scalars["String"]>;
  affiliationVerificationUrlDetail?: InputMaybe<Scalars["String"]>;
  complianceStatus?: InputMaybe<ComplianceStatus>;
  department?: InputMaybe<Scalars["String"]>;
  departmentRole?: InputMaybe<Scalars["String"]>;
  directFaxNumber?: InputMaybe<Scalars["String"]>;
  directPhoneNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  isAcceptNewPatients?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  listingUrl?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  operationHours?: InputMaybe<Array<OperationHoursInput>>;
  orgAddressLine1?: InputMaybe<Scalars["String"]>;
  orgAddressLine2?: InputMaybe<Scalars["String"]>;
  orgAddressLocality?: InputMaybe<Scalars["String"]>;
  orgAddressPostalCode?: InputMaybe<Scalars["String"]>;
  orgAddressRegion?: InputMaybe<Scalars["String"]>;
  orgFaxNumber?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
  orgNpi?: InputMaybe<Scalars["String"]>;
  orgTaxId?: InputMaybe<Scalars["String"]>;
  personalPhoneNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  profileUrl?: InputMaybe<Scalars["String"]>;
  spokenLanguages?: InputMaybe<Array<Scalars["String"]>>;
};

export type DictionaryDto = {
  __typename?: "DictionaryDto";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type ExchangeTokenMutationResponse = {
  __typename?: "ExchangeTokenMutationResponse";
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  jwt?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
  user?: Maybe<User>;
};

export type ExchangeTokensInput = {
  oneTimeToken: Scalars["String"];
};

export type FindOrganizationTaskInput = {
  orgId: Scalars["String"];
};

export type FindPersonTaskInput = {
  npi: Scalars["String"];
};

export type GeocodedAddress = {
  __typename?: "GeocodedAddress";
  addressLine1?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  houseNumber?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  labelHighlights: Array<LabelHighlight>;
  postalCode?: Maybe<Scalars["String"]>;
  resultType: Scalars["String"];
  stateCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
};

export type GeocodedAddressResponse = {
  __typename?: "GeocodedAddressResponse";
  addresses: Array<GeocodedAddress>;
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export type GetVerificatonTaskInput = {
  id: Scalars["String"];
};

export type Institution = {
  __typename?: "Institution";
  id: Scalars["ID"];
  institutionAddress: InstitutionAddress;
  linkedinUrl?: Maybe<Scalars["String"]>;
  masterOrgId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  orgType: InstitutionOrgType;
  organizationCode?: Maybe<Scalars["String"]>;
  organizationCodeSource?: Maybe<Scalars["Float"]>;
  organizationCodeType?: Maybe<Scalars["Float"]>;
  parentInstitutionId?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  twitterUrl?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  ultimateParentId?: Maybe<Scalars["Float"]>;
  website?: Maybe<Scalars["String"]>;
};

export type InstitutionAddress = {
  __typename?: "InstitutionAddress";
  address: Address;
  id: Scalars["ID"];
  institution: Institution;
};

export type InstitutionOrgType = {
  __typename?: "InstitutionOrgType";
  active: Scalars["Boolean"];
  deleted: Scalars["Boolean"];
  id: Scalars["ID"];
  institution: Institution;
  orgTypeLevel1: Scalars["String"];
  orgTypeLevel2?: Maybe<Scalars["String"]>;
  orgTypeLevel3?: Maybe<Scalars["String"]>;
};

export type LabelHighlight = {
  __typename?: "LabelHighlight";
  end: Scalars["String"];
  start: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** @deprecated This mutation will be removed use createAffiliationTaskSessions */
  completeVerificationTask: VerificationTaskMutationResponse;
  createAffiliationTask: VerificationTaskMutationResponse;
  createAffiliationTaskSessions: VerificationTaskMutationResponse;
  createAgency: AgencyMutationResponse;
  createOrganizationTask: OrganizationTaskMutationResponse;
  /** @deprecated This mutation will be removed use createOrganizationTaskSessions */
  createOrganizationTaskSession: CallSessionMutationResponse;
  createOrganizationTaskSessions: OrganizationTaskMutationResponse;
  deleteAffiliationTask: VerificationTaskMutationResponse;
  deleteOrganizationTask: OrganizationTaskMutationResponse;
  exchangeToken: ExchangeTokenMutationResponse;
  logout: MutationResponse;
  requestToken: MutationResponse;
  /** @deprecated Use findPersonTask query to get person task and use personAffiliationTasks query to get person affiliation tasks */
  startVerification: VerificationMutationResponse;
  updateAffiliationTaskSession: CallSessionMutationResponse;
  updateOrganizationTaskSession: CallSessionMutationResponse;
};

export type MutationCompleteVerificationTaskArgs = {
  input: CompleteVerificationTaskInput;
};

export type MutationCreateAffiliationTaskArgs = {
  input: AffiliationTaskInput;
};

export type MutationCreateAffiliationTaskSessionsArgs = {
  input: CreateAffiliationTaskSessionsInput;
};

export type MutationCreateAgencyArgs = {
  input: AgencyInput;
};

export type MutationCreateOrganizationTaskArgs = {
  input: OrganizationTaskInput;
};

export type MutationCreateOrganizationTaskSessionArgs = {
  input: OrganizationCallSessionInput;
};

export type MutationCreateOrganizationTaskSessionsArgs = {
  input: CreateOrganizationTaskSessionInput;
};

export type MutationDeleteAffiliationTaskArgs = {
  input: Scalars["String"];
};

export type MutationDeleteOrganizationTaskArgs = {
  input: Scalars["String"];
};

export type MutationExchangeTokenArgs = {
  input: ExchangeTokensInput;
};

export type MutationRequestTokenArgs = {
  input: RequestTokenInput;
};

export type MutationStartVerificationArgs = {
  input: StartVerificationInput;
};

export type MutationUpdateAffiliationTaskSessionArgs = {
  input: UpdateAffiliationCallSessionInput;
};

export type MutationUpdateOrganizationTaskSessionArgs = {
  input: UpdateOrganizationCallSessionInput;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export enum OperationHourType {
  Department = "DEPARTMENT",
  Organization = "ORGANIZATION"
}

export type OperationHours = {
  __typename?: "OperationHours";
  close: Scalars["String"];
  created: Scalars["DateTime"];
  day: Scalars["String"];
  id: Scalars["ID"];
  open: Scalars["String"];
  type: OperationHourType;
  updated: Scalars["DateTime"];
  verificationUpdate?: Maybe<VerificationUpdate>;
  verificationUpdateId?: Maybe<Scalars["String"]>;
};

export type OperationHoursInput = {
  close?: InputMaybe<Scalars["String"]>;
  day: Scalars["String"];
  open?: InputMaybe<Scalars["String"]>;
  type: OperationHourType;
};

export type OrgAffiliationFilterInput = {
  completed?: InputMaybe<Scalars["Boolean"]>;
  orgId: Scalars["String"];
};

export type OrgAffiliationTaskInput = {
  filters: OrgAffiliationFilterInput;
  page?: InputMaybe<PageInput>;
};

export type OrgType = {
  __typename?: "OrgType";
  level1: Scalars["String"];
  level2?: Maybe<Scalars["String"]>;
  level3?: Maybe<Scalars["String"]>;
};

export type OrganizationCallSessionInput = {
  notes?: InputMaybe<Scalars["String"]>;
  phoneExtension?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  phoneType: PhoneNumberType;
  respondentDepartment?: InputMaybe<Scalars["String"]>;
  respondentFirstName?: InputMaybe<Scalars["String"]>;
  respondentLastName?: InputMaybe<Scalars["String"]>;
  respondentTitle?: InputMaybe<Scalars["String"]>;
  status: TaskStatus;
  statusReason: TaskStatusReason;
  update: OrganizationSessionUpdateInput;
  verificationTaskId?: InputMaybe<Scalars["String"]>;
};

export type OrganizationData = {
  __typename?: "OrganizationData";
  affiliationVerificationUrl?: Maybe<Scalars["String"]>;
  affiliationVerificationUrlDetail?: Maybe<Scalars["String"]>;
  domainName?: Maybe<Scalars["String"]>;
  operationHours?: Maybe<Array<OperationHours>>;
  orgAddressLine1?: Maybe<Scalars["String"]>;
  orgAddressLine2?: Maybe<Scalars["String"]>;
  orgAddressLocality?: Maybe<Scalars["String"]>;
  orgAddressPostalCode?: Maybe<Scalars["String"]>;
  orgAddressRegion?: Maybe<Scalars["String"]>;
  orgFaxNumber?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
};

export type OrganizationDataInput = {
  orgAddressLine1: Scalars["String"];
  orgAddressLine2?: InputMaybe<Scalars["String"]>;
  orgAddressLocality: Scalars["String"];
  orgAddressPostalCode: Scalars["String"];
  orgAddressRegion: Scalars["String"];
  orgName: Scalars["String"];
  orgType: Scalars["String"];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
};

export type OrganizationSessionUpdateInput = {
  affiliationVerificationUrl?: InputMaybe<Scalars["String"]>;
  affiliationVerificationUrlDetail?: InputMaybe<Scalars["String"]>;
  domainName?: InputMaybe<Scalars["String"]>;
  isAutocompleted?: InputMaybe<Scalars["Boolean"]>;
  operationHours?: InputMaybe<Array<OperationHoursInput>>;
  orgAddressLine1?: InputMaybe<Scalars["String"]>;
  orgAddressLine2?: InputMaybe<Scalars["String"]>;
  orgAddressLocality?: InputMaybe<Scalars["String"]>;
  orgAddressPostalCode?: InputMaybe<Scalars["String"]>;
  orgAddressRegion?: InputMaybe<Scalars["String"]>;
  orgFaxNumber?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
};

export type OrganizationTask = {
  __typename?: "OrganizationTask";
  campaignId?: Maybe<Scalars["String"]>;
  completed: Scalars["Boolean"];
  completedBy?: Maybe<User>;
  created: Scalars["DateTime"];
  data?: Maybe<OrganizationData>;
  id: Scalars["ID"];
  lastSession?: Maybe<CallTaskSession>;
  /** @deprecated This field will be deprecated, use notes inside taskSessions instead */
  notes?: Maybe<Scalars["String"]>;
  orgMasterId: Scalars["String"];
  organization?: Maybe<Institution>;
  personNpi?: Maybe<Scalars["String"]>;
  phoneNumbers: Array<TaskPhoneNumber>;
  taskSessions?: Maybe<Array<TaskSession>>;
  taskType: TaskType;
  updated: Scalars["DateTime"];
};

export type OrganizationTaskData = {
  __typename?: "OrganizationTaskData";
  address: TaskAddress;
  faxNumber?: Maybe<Scalars["String"]>;
  masterOrgId: Scalars["String"];
  name: Scalars["String"];
  npi?: Maybe<Scalars["String"]>;
  orgType: OrgType;
  phone?: Maybe<PhoneNumber>;
  taxId?: Maybe<Scalars["String"]>;
};

export type OrganizationTaskInput = {
  initialData: OrganizationDataInput;
  orgId: Scalars["String"];
};

export type OrganizationTaskMutationResponse = {
  __typename?: "OrganizationTaskMutationResponse";
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
  task?: Maybe<OrganizationTask>;
};

export type PageInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PaginatedVerificationTasks = {
  __typename?: "PaginatedVerificationTasks";
  count: Scalars["Int"];
  items: Array<VerificationTask>;
};

export type Person = {
  __typename?: "Person";
  designation?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  npi?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  primarySpecialty?: Maybe<Scalars["String"]>;
  secondarySpecialty?: Maybe<Scalars["String"]>;
  specialties: Array<Scalars["String"]>;
};

export type PersonAffiliationFilterInput = {
  completed?: InputMaybe<Scalars["Boolean"]>;
  npi: Scalars["String"];
};

export type PersonDataInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName: Scalars["String"];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
};

export type PersonInstitution = {
  __typename?: "PersonInstitution";
  active: Scalars["Boolean"];
  affiliations: Array<Affiliation>;
  deleted: Scalars["Boolean"];
  directPhone?: Maybe<Scalars["String"]>;
  directPhoneExtension?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  harvestedDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  institution: Institution;
  masterOrgId?: Maybe<Scalars["String"]>;
  personId: Scalars["String"];
  personInstitutionAddresses: Array<PersonInstitutionAddress>;
  pingStatus?: Maybe<Scalars["Float"]>;
  source?: Maybe<Scalars["String"]>;
};

export type PersonInstitutionAddress = {
  __typename?: "PersonInstitutionAddress";
  address: Address;
  id: Scalars["ID"];
  personInstitution: PersonInstitution;
};

export type PersonTask = {
  __typename?: "PersonTask";
  campaignId?: Maybe<Scalars["String"]>;
  completed: Scalars["Boolean"];
  completedBy?: Maybe<User>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  initialData: PersonTaskData;
  /** @deprecated This field will be deprecated, use notes inside taskSessions instead */
  notes?: Maybe<Scalars["String"]>;
  orgMasterId?: Maybe<Scalars["String"]>;
  person?: Maybe<Person>;
  personNpi?: Maybe<Scalars["String"]>;
  taskSessions?: Maybe<Array<TaskSession>>;
  taskType: TaskType;
  updated: Scalars["DateTime"];
};

export type PersonTaskData = {
  __typename?: "PersonTaskData";
  designations: Array<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: Maybe<Scalars["String"]>;
  npi: Scalars["String"];
  phone?: Maybe<PhoneNumber>;
  specialties: Array<Scalars["String"]>;
};

export type PhoneNumber = {
  __typename?: "PhoneNumber";
  extension?: Maybe<Scalars["String"]>;
  number: Scalars["String"];
};

export type PhoneNumberInput = {
  extension?: InputMaybe<Scalars["String"]>;
  number: Scalars["String"];
};

export enum PhoneNumberType {
  Direct = "DIRECT",
  Organization = "ORGANIZATION",
  Personal = "PERSONAL",
  Unknown = "UNKNOWN"
}

export type Query = {
  __typename?: "Query";
  allowedLanguages: Array<DictionaryDto>;
  allowedStates: Array<DictionaryDto>;
  autocompleteAddress: GeocodedAddressResponse;
  findOrganizationTask?: Maybe<OrganizationTask>;
  findPersonTask?: Maybe<PersonTask>;
  getVerificationTask?: Maybe<VerificationTask>;
  me: User;
  orgAffiliationTasks: PaginatedVerificationTasks;
  personAffiliationTasks: Array<VerificationTask>;
};

export type QueryAutocompleteAddressArgs = {
  input: AutocompleteAddressInput;
};

export type QueryFindOrganizationTaskArgs = {
  input: FindOrganizationTaskInput;
};

export type QueryFindPersonTaskArgs = {
  input: FindPersonTaskInput;
};

export type QueryGetVerificationTaskArgs = {
  input: GetVerificatonTaskInput;
};

export type QueryOrgAffiliationTasksArgs = {
  input: OrgAffiliationTaskInput;
};

export type QueryPersonAffiliationTasksArgs = {
  input: PersonAffiliationFilterInput;
};

export type RequestTokenInput = {
  email: Scalars["String"];
};

export type SessionUpdate = {
  __typename?: "SessionUpdate";
  created: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  id: Scalars["ID"];
  updated: Scalars["DateTime"];
};

export type StartVerificationInput = {
  npi: Scalars["String"];
};

export type TaskAddress = {
  __typename?: "TaskAddress";
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  locality: Scalars["String"];
  postalCode: Scalars["String"];
  region: Scalars["String"];
};

export type TaskPhoneNumber = {
  __typename?: "TaskPhoneNumber";
  callStatus?: Maybe<TaskStatusReason>;
  called: Scalars["Boolean"];
  extension?: Maybe<Scalars["String"]>;
  number: Scalars["String"];
  respondentDepartment?: Maybe<Scalars["String"]>;
  respondentFirstName?: Maybe<Scalars["String"]>;
  respondentLastName?: Maybe<Scalars["String"]>;
  respondentTitle?: Maybe<Scalars["String"]>;
  type?: Maybe<PhoneNumberType>;
};

export type TaskSession = {
  __typename?: "TaskSession";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  statusReason: Scalars["String"];
  updated: Scalars["DateTime"];
};

export enum TaskStatus {
  Complete = "COMPLETE",
  Deferred = "DEFERRED",
  Incomplete = "INCOMPLETE"
}

export enum TaskStatusReason {
  DeclinedVerification = "DECLINED_VERIFICATION",
  DoNotCall = "DO_NOT_CALL",
  NoAnswer = "NO_ANSWER",
  Verified = "VERIFIED",
  VerifyThroughFax = "VERIFY_THROUGH_FAX",
  WrongNumber = "WRONG_NUMBER"
}

export enum TaskType {
  Affiliation = "AFFILIATION",
  Organization = "ORGANIZATION",
  Person = "PERSON"
}

export type UpdateAffiliationCallSessionInput = {
  id: Scalars["String"];
  update: AffiliationSessionUpdateInput;
};

export type UpdateOrganizationCallSessionInput = {
  id: Scalars["String"];
  update: OrganizationSessionUpdateInput;
};

export type User = {
  __typename?: "User";
  agency?: Maybe<Agency>;
  blocked: Scalars["Boolean"];
  calls?: Maybe<Array<Call>>;
  created: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  id: Scalars["String"];
  lastLogin?: Maybe<Scalars["DateTime"]>;
  taskSessions?: Maybe<Array<TaskSession>>;
  updated: Scalars["DateTime"];
};

export type ValidationResultDto = {
  __typename?: "ValidationResultDto";
  code: Scalars["String"];
  field: Scalars["String"];
  message: Scalars["String"];
};

export type Verification = {
  __typename?: "Verification";
  person: Person;
  tasks: Array<VerificationTask>;
};

export type VerificationData = {
  __typename?: "VerificationData";
  affiliationId?: Maybe<Scalars["String"]>;
  affiliationStatus?: Maybe<AffiliationStatus>;
  appointmentPhoneNumber?: Maybe<Scalars["String"]>;
  appointmentPhoneNumberExt?: Maybe<Scalars["String"]>;
  complianceStatus?: Maybe<ComplianceStatus>;
  created?: Maybe<Scalars["DateTime"]>;
  department?: Maybe<Scalars["String"]>;
  departmentRole?: Maybe<Scalars["String"]>;
  directFaxNumber?: Maybe<Scalars["String"]>;
  directPhoneNumber?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isAcceptNewPatients?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  operationHours?: Maybe<Array<OperationHours>>;
  orgAddressLine1?: Maybe<Scalars["String"]>;
  orgAddressLine2?: Maybe<Scalars["String"]>;
  orgAddressLocality?: Maybe<Scalars["String"]>;
  orgAddressPostalCode?: Maybe<Scalars["String"]>;
  orgAddressRegion?: Maybe<Scalars["String"]>;
  orgFaxNumber?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
  orgNpi?: Maybe<Scalars["String"]>;
  orgTaxId?: Maybe<Scalars["String"]>;
  orgType: InstitutionOrgType;
  personalPhoneNumber?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profileUrl?: Maybe<Scalars["String"]>;
  spokenLanguages?: Maybe<Array<Scalars["String"]>>;
  updated?: Maybe<Scalars["DateTime"]>;
};

export type VerificationMutationResponse = {
  __typename?: "VerificationMutationResponse";
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
  verification?: Maybe<Verification>;
};

export type VerificationTask = {
  __typename?: "VerificationTask";
  affiliationId?: Maybe<Scalars["String"]>;
  /** @deprecated This field will be deprecated, use taskSessions instead */
  calls?: Maybe<Array<Call>>;
  campaignId?: Maybe<Scalars["String"]>;
  completed: Scalars["Boolean"];
  completedBy?: Maybe<User>;
  created: Scalars["DateTime"];
  data: VerificationData;
  id: Scalars["ID"];
  initialData: AffiliationTaskData;
  lastSession?: Maybe<CallTaskSession>;
  /** @deprecated This field will be deprecated, use notes inside taskSessions instead */
  notes?: Maybe<Scalars["String"]>;
  orgMasterId?: Maybe<Scalars["String"]>;
  personNpi?: Maybe<Scalars["String"]>;
  phoneNumbers: Array<TaskPhoneNumber>;
  taskSessions?: Maybe<Array<TaskSession>>;
  taskType: TaskType;
  updated: Scalars["DateTime"];
};

export type VerificationTaskMutationResponse = {
  __typename?: "VerificationTaskMutationResponse";
  code: Scalars["String"];
  errors: Array<ValidationResultDto>;
  message: Scalars["String"];
  success: Scalars["Boolean"];
  task?: Maybe<VerificationTask>;
};

export type VerificationUpdate = {
  __typename?: "VerificationUpdate";
  affiliationId?: Maybe<Scalars["String"]>;
  affiliationStatus: AffiliationStatus;
  call?: Maybe<Call>;
  complianceStatus?: Maybe<ComplianceStatus>;
  created: Scalars["DateTime"];
  department?: Maybe<Scalars["String"]>;
  departmentRole?: Maybe<Scalars["String"]>;
  directFaxNumber?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isAcceptNewPatients?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  operationHours?: Maybe<Array<OperationHours>>;
  orgAddressLine1?: Maybe<Scalars["String"]>;
  orgAddressLine2?: Maybe<Scalars["String"]>;
  orgAddressLocality?: Maybe<Scalars["String"]>;
  orgAddressPostalCode?: Maybe<Scalars["String"]>;
  orgAddressRegion?: Maybe<Scalars["String"]>;
  orgFaxNumber?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
  orgNpi?: Maybe<Scalars["String"]>;
  orgTaxId?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profileUrl?: Maybe<Scalars["String"]>;
  spokenLanguages?: Maybe<Array<Scalars["String"]>>;
  updated?: Maybe<Scalars["DateTime"]>;
};

export type AddressFragment = {
  __typename?: "Address";
  id: string;
  line1?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
};

export type CallTaskSessionFragment = {
  __typename?: "CallTaskSession";
  id: string;
  status: string;
  statusReason: string;
  created: string;
  updated: string;
  notes?: string | null;
  lastUpdate?: {
    __typename?: "SessionUpdate";
    created: string;
    createdBy?: { __typename?: "User"; id: string; email: string } | null;
  } | null;
};

export type OperationHoursFragment = {
  __typename?: "OperationHours";
  open: string;
  close: string;
  day: string;
  type: OperationHourType;
};

export type OrganizationTaskFragment = {
  __typename?: "OrganizationTask";
  id: string;
  completed: boolean;
  notes?: string | null;
  orgMasterId: string;
  updated: string;
  completedBy?: { __typename?: "User"; id: string; email: string } | null;
  data?: {
    __typename?: "OrganizationData";
    orgName?: string | null;
    orgAddressLine1?: string | null;
    orgAddressLine2?: string | null;
    orgAddressLocality?: string | null;
    orgAddressRegion?: string | null;
    orgAddressPostalCode?: string | null;
    orgFaxNumber?: string | null;
    domainName?: string | null;
    affiliationVerificationUrl?: string | null;
    affiliationVerificationUrlDetail?: string | null;
    operationHours?: Array<{
      __typename?: "OperationHours";
      open: string;
      close: string;
      day: string;
      type: OperationHourType;
    }> | null;
  } | null;
  organization?: {
    __typename?: "Institution";
    type?: string | null;
    name?: string | null;
    institutionAddress: {
      __typename?: "InstitutionAddress";
      address: {
        __typename?: "Address";
        id: string;
        line1?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
      };
    };
  } | null;
  phoneNumbers: Array<{
    __typename?: "TaskPhoneNumber";
    number: string;
    extension?: string | null;
    type?: PhoneNumberType | null;
    called: boolean;
    callStatus?: TaskStatusReason | null;
    respondentFirstName?: string | null;
    respondentLastName?: string | null;
    respondentDepartment?: string | null;
    respondentTitle?: string | null;
  }>;
  lastSession?: {
    __typename?: "CallTaskSession";
    id: string;
    status: string;
    statusReason: string;
    created: string;
    updated: string;
    notes?: string | null;
    lastUpdate?: {
      __typename?: "SessionUpdate";
      created: string;
      createdBy?: { __typename?: "User"; id: string; email: string } | null;
    } | null;
  } | null;
};

export type PersonFragment = {
  __typename?: "Person";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  npi?: string | null;
  phoneNumber?: string | null;
  designation?: string | null;
  email?: string | null;
  specialties: Array<string>;
};

export type TaskAddressFragment = {
  __typename?: "TaskAddress";
  line1: string;
  line2?: string | null;
  locality: string;
  postalCode: string;
  region: string;
};

export type TaskPhoneNumberFragment = {
  __typename?: "TaskPhoneNumber";
  number: string;
  extension?: string | null;
  type?: PhoneNumberType | null;
  called: boolean;
  callStatus?: TaskStatusReason | null;
  respondentFirstName?: string | null;
  respondentLastName?: string | null;
  respondentDepartment?: string | null;
  respondentTitle?: string | null;
};

export type TaskSessionFragment = {
  __typename?: "TaskSession";
  id: string;
  notes?: string | null;
  status: string;
  statusReason: string;
  created: string;
  updated: string;
};

export type UserFragment = { __typename?: "User"; id: string; email: string };

export type ValidationResultDtoFragment = {
  __typename?: "ValidationResultDto";
  field: string;
  code: string;
  message: string;
};

export type VerificationFragment = {
  __typename?: "Verification";
  person: {
    __typename?: "Person";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    npi?: string | null;
    phoneNumber?: string | null;
    designation?: string | null;
    email?: string | null;
    specialties: Array<string>;
  };
  tasks: Array<{
    __typename?: "VerificationTask";
    id: string;
    notes?: string | null;
    personNpi?: string | null;
    completed: boolean;
    updated: string;
    initialData: {
      __typename?: "AffiliationTaskData";
      institution: {
        __typename?: "OrganizationTaskData";
        name: string;
        npi?: string | null;
        masterOrgId: string;
        taxId?: string | null;
        faxNumber?: string | null;
        orgType: {
          __typename?: "OrgType";
          level1: string;
          level2?: string | null;
          level3?: string | null;
        };
        address: {
          __typename?: "TaskAddress";
          line1: string;
          line2?: string | null;
          locality: string;
          postalCode: string;
          region: string;
        };
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
      person: {
        __typename?: "PersonTaskData";
        firstName: string;
        lastName: string;
        npi: string;
        middleName?: string | null;
        email?: string | null;
        designations: Array<string>;
        specialties: Array<string>;
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
    };
    completedBy?: { __typename?: "User"; id: string; email: string } | null;
    data: {
      __typename?: "VerificationData";
      affiliationId?: string | null;
      phoneNumber?: string | null;
      orgName?: string | null;
      orgAddressLine1?: string | null;
      orgAddressLine2?: string | null;
      orgAddressLocality?: string | null;
      orgAddressRegion?: string | null;
      orgAddressPostalCode?: string | null;
      orgNpi?: string | null;
      orgTaxId?: string | null;
      affiliationStatus?: AffiliationStatus | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      nickname?: string | null;
      department?: string | null;
      departmentRole?: string | null;
      email?: string | null;
      isAcceptNewPatients?: boolean | null;
      directFaxNumber?: string | null;
      spokenLanguages?: Array<string> | null;
      profileUrl?: string | null;
      listingUrl?: string | null;
      personalPhoneNumber?: string | null;
      directPhoneNumber?: string | null;
      appointmentPhoneNumber?: string | null;
      appointmentPhoneNumberExt?: string | null;
      orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
      operationHours?: Array<{
        __typename?: "OperationHours";
        open: string;
        close: string;
        day: string;
        type: OperationHourType;
      }> | null;
    };
    phoneNumbers: Array<{
      __typename?: "TaskPhoneNumber";
      number: string;
      extension?: string | null;
      type?: PhoneNumberType | null;
      called: boolean;
      callStatus?: TaskStatusReason | null;
      respondentFirstName?: string | null;
      respondentLastName?: string | null;
      respondentDepartment?: string | null;
      respondentTitle?: string | null;
    }>;
    lastSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
  }>;
};

export type VerificationDataFragment = {
  __typename?: "VerificationData";
  affiliationId?: string | null;
  phoneNumber?: string | null;
  orgName?: string | null;
  orgAddressLine1?: string | null;
  orgAddressLine2?: string | null;
  orgAddressLocality?: string | null;
  orgAddressRegion?: string | null;
  orgAddressPostalCode?: string | null;
  orgNpi?: string | null;
  orgTaxId?: string | null;
  affiliationStatus?: AffiliationStatus | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  nickname?: string | null;
  department?: string | null;
  departmentRole?: string | null;
  email?: string | null;
  isAcceptNewPatients?: boolean | null;
  directFaxNumber?: string | null;
  spokenLanguages?: Array<string> | null;
  profileUrl?: string | null;
  listingUrl?: string | null;
  personalPhoneNumber?: string | null;
  directPhoneNumber?: string | null;
  appointmentPhoneNumber?: string | null;
  appointmentPhoneNumberExt?: string | null;
  orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
  operationHours?: Array<{
    __typename?: "OperationHours";
    open: string;
    close: string;
    day: string;
    type: OperationHourType;
  }> | null;
};

export type VerificationTaskFragment = {
  __typename?: "VerificationTask";
  id: string;
  notes?: string | null;
  personNpi?: string | null;
  completed: boolean;
  updated: string;
  initialData: {
    __typename?: "AffiliationTaskData";
    institution: {
      __typename?: "OrganizationTaskData";
      name: string;
      npi?: string | null;
      masterOrgId: string;
      taxId?: string | null;
      faxNumber?: string | null;
      orgType: {
        __typename?: "OrgType";
        level1: string;
        level2?: string | null;
        level3?: string | null;
      };
      address: {
        __typename?: "TaskAddress";
        line1: string;
        line2?: string | null;
        locality: string;
        postalCode: string;
        region: string;
      };
      phone?: {
        __typename?: "PhoneNumber";
        number: string;
        extension?: string | null;
      } | null;
    };
    person: {
      __typename?: "PersonTaskData";
      firstName: string;
      lastName: string;
      npi: string;
      middleName?: string | null;
      email?: string | null;
      designations: Array<string>;
      specialties: Array<string>;
      phone?: {
        __typename?: "PhoneNumber";
        number: string;
        extension?: string | null;
      } | null;
    };
  };
  completedBy?: { __typename?: "User"; id: string; email: string } | null;
  data: {
    __typename?: "VerificationData";
    affiliationId?: string | null;
    phoneNumber?: string | null;
    orgName?: string | null;
    orgAddressLine1?: string | null;
    orgAddressLine2?: string | null;
    orgAddressLocality?: string | null;
    orgAddressRegion?: string | null;
    orgAddressPostalCode?: string | null;
    orgNpi?: string | null;
    orgTaxId?: string | null;
    affiliationStatus?: AffiliationStatus | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    nickname?: string | null;
    department?: string | null;
    departmentRole?: string | null;
    email?: string | null;
    isAcceptNewPatients?: boolean | null;
    directFaxNumber?: string | null;
    spokenLanguages?: Array<string> | null;
    profileUrl?: string | null;
    listingUrl?: string | null;
    personalPhoneNumber?: string | null;
    directPhoneNumber?: string | null;
    appointmentPhoneNumber?: string | null;
    appointmentPhoneNumberExt?: string | null;
    orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
    operationHours?: Array<{
      __typename?: "OperationHours";
      open: string;
      close: string;
      day: string;
      type: OperationHourType;
    }> | null;
  };
  phoneNumbers: Array<{
    __typename?: "TaskPhoneNumber";
    number: string;
    extension?: string | null;
    type?: PhoneNumberType | null;
    called: boolean;
    callStatus?: TaskStatusReason | null;
    respondentFirstName?: string | null;
    respondentLastName?: string | null;
    respondentDepartment?: string | null;
    respondentTitle?: string | null;
  }>;
  lastSession?: {
    __typename?: "CallTaskSession";
    id: string;
    status: string;
    statusReason: string;
    created: string;
    updated: string;
    notes?: string | null;
    lastUpdate?: {
      __typename?: "SessionUpdate";
      created: string;
      createdBy?: { __typename?: "User"; id: string; email: string } | null;
    } | null;
  } | null;
};

export type CreateAffiliationTaskSessionsMutationVariables = Exact<{
  verificationTaskId: Scalars["String"];
  taskSessions: Array<AffiliationCallSessionInput>;
}>;

export type CreateAffiliationTaskSessionsMutation = {
  __typename?: "Mutation";
  createAffiliationTaskSessions: {
    __typename?: "VerificationTaskMutationResponse";
    code: string;
    success: boolean;
    message: string;
    task?: {
      __typename?: "VerificationTask";
      id: string;
      notes?: string | null;
      personNpi?: string | null;
      completed: boolean;
      updated: string;
      initialData: {
        __typename?: "AffiliationTaskData";
        institution: {
          __typename?: "OrganizationTaskData";
          name: string;
          npi?: string | null;
          masterOrgId: string;
          taxId?: string | null;
          faxNumber?: string | null;
          orgType: {
            __typename?: "OrgType";
            level1: string;
            level2?: string | null;
            level3?: string | null;
          };
          address: {
            __typename?: "TaskAddress";
            line1: string;
            line2?: string | null;
            locality: string;
            postalCode: string;
            region: string;
          };
          phone?: {
            __typename?: "PhoneNumber";
            number: string;
            extension?: string | null;
          } | null;
        };
        person: {
          __typename?: "PersonTaskData";
          firstName: string;
          lastName: string;
          npi: string;
          middleName?: string | null;
          email?: string | null;
          designations: Array<string>;
          specialties: Array<string>;
          phone?: {
            __typename?: "PhoneNumber";
            number: string;
            extension?: string | null;
          } | null;
        };
      };
      completedBy?: { __typename?: "User"; id: string; email: string } | null;
      data: {
        __typename?: "VerificationData";
        affiliationId?: string | null;
        phoneNumber?: string | null;
        orgName?: string | null;
        orgAddressLine1?: string | null;
        orgAddressLine2?: string | null;
        orgAddressLocality?: string | null;
        orgAddressRegion?: string | null;
        orgAddressPostalCode?: string | null;
        orgNpi?: string | null;
        orgTaxId?: string | null;
        affiliationStatus?: AffiliationStatus | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        nickname?: string | null;
        department?: string | null;
        departmentRole?: string | null;
        email?: string | null;
        isAcceptNewPatients?: boolean | null;
        directFaxNumber?: string | null;
        spokenLanguages?: Array<string> | null;
        profileUrl?: string | null;
        listingUrl?: string | null;
        personalPhoneNumber?: string | null;
        directPhoneNumber?: string | null;
        appointmentPhoneNumber?: string | null;
        appointmentPhoneNumberExt?: string | null;
        orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
        operationHours?: Array<{
          __typename?: "OperationHours";
          open: string;
          close: string;
          day: string;
          type: OperationHourType;
        }> | null;
      };
      phoneNumbers: Array<{
        __typename?: "TaskPhoneNumber";
        number: string;
        extension?: string | null;
        type?: PhoneNumberType | null;
        called: boolean;
        callStatus?: TaskStatusReason | null;
        respondentFirstName?: string | null;
        respondentLastName?: string | null;
        respondentDepartment?: string | null;
        respondentTitle?: string | null;
      }>;
      lastSession?: {
        __typename?: "CallTaskSession";
        id: string;
        status: string;
        statusReason: string;
        created: string;
        updated: string;
        notes?: string | null;
        lastUpdate?: {
          __typename?: "SessionUpdate";
          created: string;
          createdBy?: { __typename?: "User"; id: string; email: string } | null;
        } | null;
      } | null;
    } | null;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
  };
};

export type CreateOrganizationTaskSessionMutationVariables = Exact<{
  verificationTaskId: Scalars["String"];
  taskSessions: Array<OrganizationCallSessionInput>;
}>;

export type CreateOrganizationTaskSessionMutation = {
  __typename?: "Mutation";
  createOrganizationTaskSessions: {
    __typename?: "OrganizationTaskMutationResponse";
    code: string;
    success: boolean;
    message: string;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
    task?: {
      __typename?: "OrganizationTask";
      id: string;
      completed: boolean;
      notes?: string | null;
      orgMasterId: string;
      updated: string;
      completedBy?: { __typename?: "User"; id: string; email: string } | null;
      data?: {
        __typename?: "OrganizationData";
        orgName?: string | null;
        orgAddressLine1?: string | null;
        orgAddressLine2?: string | null;
        orgAddressLocality?: string | null;
        orgAddressRegion?: string | null;
        orgAddressPostalCode?: string | null;
        orgFaxNumber?: string | null;
        domainName?: string | null;
        affiliationVerificationUrl?: string | null;
        affiliationVerificationUrlDetail?: string | null;
        operationHours?: Array<{
          __typename?: "OperationHours";
          open: string;
          close: string;
          day: string;
          type: OperationHourType;
        }> | null;
      } | null;
      organization?: {
        __typename?: "Institution";
        type?: string | null;
        name?: string | null;
        institutionAddress: {
          __typename?: "InstitutionAddress";
          address: {
            __typename?: "Address";
            id: string;
            line1?: string | null;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
          };
        };
      } | null;
      phoneNumbers: Array<{
        __typename?: "TaskPhoneNumber";
        number: string;
        extension?: string | null;
        type?: PhoneNumberType | null;
        called: boolean;
        callStatus?: TaskStatusReason | null;
        respondentFirstName?: string | null;
        respondentLastName?: string | null;
        respondentDepartment?: string | null;
        respondentTitle?: string | null;
      }>;
      lastSession?: {
        __typename?: "CallTaskSession";
        id: string;
        status: string;
        statusReason: string;
        created: string;
        updated: string;
        notes?: string | null;
        lastUpdate?: {
          __typename?: "SessionUpdate";
          created: string;
          createdBy?: { __typename?: "User"; id: string; email: string } | null;
        } | null;
      } | null;
    } | null;
  };
};

export type ExchangeTokenMutationVariables = Exact<{
  oneTimeToken: Scalars["String"];
}>;

export type ExchangeTokenMutation = {
  __typename?: "Mutation";
  exchangeToken: {
    __typename?: "ExchangeTokenMutationResponse";
    code: string;
    success: boolean;
    message: string;
    jwt?: string | null;
    user?: { __typename?: "User"; id: string; email: string } | null;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: {
    __typename?: "MutationResponse";
    code: string;
    success: boolean;
    message: string;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
  };
};

export type RequestTokenMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestTokenMutation = {
  __typename?: "Mutation";
  requestToken: {
    __typename?: "MutationResponse";
    code: string;
    message: string;
    success: boolean;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
  };
};

export type UpdateAffiliationTaskSessionMutationVariables = Exact<{
  id: Scalars["String"];
  update: AffiliationSessionUpdateInput;
}>;

export type UpdateAffiliationTaskSessionMutation = {
  __typename?: "Mutation";
  updateAffiliationTaskSession: {
    __typename?: "CallSessionMutationResponse";
    code: string;
    success: boolean;
    message: string;
    callSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
  };
};

export type UpdateOrganizationTaskSessionMutationVariables = Exact<{
  id: Scalars["String"];
  update: OrganizationSessionUpdateInput;
}>;

export type UpdateOrganizationTaskSessionMutation = {
  __typename?: "Mutation";
  updateOrganizationTaskSession: {
    __typename?: "CallSessionMutationResponse";
    code: string;
    success: boolean;
    message: string;
    errors: Array<{
      __typename?: "ValidationResultDto";
      field: string;
      code: string;
      message: string;
    }>;
    callSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
  };
};

export type AllowedLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllowedLanguagesQuery = {
  __typename?: "Query";
  allowedLanguages: Array<{
    __typename?: "DictionaryDto";
    value: string;
    label: string;
  }>;
};

export type AllowedStatesQueryVariables = Exact<{ [key: string]: never }>;

export type AllowedStatesQuery = {
  __typename?: "Query";
  allowedStates: Array<{
    __typename?: "DictionaryDto";
    value: string;
    label: string;
  }>;
};

export type AutocompleteAddressQueryVariables = Exact<{
  input: AutocompleteAddressInput;
}>;

export type AutocompleteAddressQuery = {
  __typename?: "Query";
  autocompleteAddress: {
    __typename?: "GeocodedAddressResponse";
    code: string;
    success: boolean;
    addresses: Array<{
      __typename?: "GeocodedAddress";
      city?: string | null;
      houseNumber?: string | null;
      label: string;
      postalCode?: string | null;
      stateCode?: string | null;
      resultType: string;
      street?: string | null;
      addressLine1?: string | null;
      labelHighlights: Array<{
        __typename?: "LabelHighlight";
        start: string;
        end: string;
      }>;
    }>;
  };
};

export type FindOrganizationTaskQueryVariables = Exact<{
  orgId: Scalars["String"];
}>;

export type FindOrganizationTaskQuery = {
  __typename?: "Query";
  findOrganizationTask?: {
    __typename?: "OrganizationTask";
    id: string;
    completed: boolean;
    notes?: string | null;
    orgMasterId: string;
    updated: string;
    completedBy?: { __typename?: "User"; id: string; email: string } | null;
    data?: {
      __typename?: "OrganizationData";
      orgName?: string | null;
      orgAddressLine1?: string | null;
      orgAddressLine2?: string | null;
      orgAddressLocality?: string | null;
      orgAddressRegion?: string | null;
      orgAddressPostalCode?: string | null;
      orgFaxNumber?: string | null;
      domainName?: string | null;
      affiliationVerificationUrl?: string | null;
      affiliationVerificationUrlDetail?: string | null;
      operationHours?: Array<{
        __typename?: "OperationHours";
        open: string;
        close: string;
        day: string;
        type: OperationHourType;
      }> | null;
    } | null;
    organization?: {
      __typename?: "Institution";
      type?: string | null;
      name?: string | null;
      institutionAddress: {
        __typename?: "InstitutionAddress";
        address: {
          __typename?: "Address";
          id: string;
          line1?: string | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
        };
      };
    } | null;
    phoneNumbers: Array<{
      __typename?: "TaskPhoneNumber";
      number: string;
      extension?: string | null;
      type?: PhoneNumberType | null;
      called: boolean;
      callStatus?: TaskStatusReason | null;
      respondentFirstName?: string | null;
      respondentLastName?: string | null;
      respondentDepartment?: string | null;
      respondentTitle?: string | null;
    }>;
    lastSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
  } | null;
};

export type FindPersonTaskQueryVariables = Exact<{
  npi: Scalars["String"];
}>;

export type FindPersonTaskQuery = {
  __typename?: "Query";
  findPersonTask?: {
    __typename?: "PersonTask";
    id: string;
    person?: {
      __typename?: "Person";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      npi?: string | null;
      phoneNumber?: string | null;
      designation?: string | null;
      email?: string | null;
      specialties: Array<string>;
    } | null;
  } | null;
};

export type GetVerificationTaskQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetVerificationTaskQuery = {
  __typename?: "Query";
  getVerificationTask?: {
    __typename?: "VerificationTask";
    id: string;
    notes?: string | null;
    personNpi?: string | null;
    completed: boolean;
    updated: string;
    initialData: {
      __typename?: "AffiliationTaskData";
      institution: {
        __typename?: "OrganizationTaskData";
        name: string;
        npi?: string | null;
        masterOrgId: string;
        taxId?: string | null;
        faxNumber?: string | null;
        orgType: {
          __typename?: "OrgType";
          level1: string;
          level2?: string | null;
          level3?: string | null;
        };
        address: {
          __typename?: "TaskAddress";
          line1: string;
          line2?: string | null;
          locality: string;
          postalCode: string;
          region: string;
        };
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
      person: {
        __typename?: "PersonTaskData";
        firstName: string;
        lastName: string;
        npi: string;
        middleName?: string | null;
        email?: string | null;
        designations: Array<string>;
        specialties: Array<string>;
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
    };
    completedBy?: { __typename?: "User"; id: string; email: string } | null;
    data: {
      __typename?: "VerificationData";
      affiliationId?: string | null;
      phoneNumber?: string | null;
      orgName?: string | null;
      orgAddressLine1?: string | null;
      orgAddressLine2?: string | null;
      orgAddressLocality?: string | null;
      orgAddressRegion?: string | null;
      orgAddressPostalCode?: string | null;
      orgNpi?: string | null;
      orgTaxId?: string | null;
      affiliationStatus?: AffiliationStatus | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      nickname?: string | null;
      department?: string | null;
      departmentRole?: string | null;
      email?: string | null;
      isAcceptNewPatients?: boolean | null;
      directFaxNumber?: string | null;
      spokenLanguages?: Array<string> | null;
      profileUrl?: string | null;
      listingUrl?: string | null;
      personalPhoneNumber?: string | null;
      directPhoneNumber?: string | null;
      appointmentPhoneNumber?: string | null;
      appointmentPhoneNumberExt?: string | null;
      orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
      operationHours?: Array<{
        __typename?: "OperationHours";
        open: string;
        close: string;
        day: string;
        type: OperationHourType;
      }> | null;
    };
    phoneNumbers: Array<{
      __typename?: "TaskPhoneNumber";
      number: string;
      extension?: string | null;
      type?: PhoneNumberType | null;
      called: boolean;
      callStatus?: TaskStatusReason | null;
      respondentFirstName?: string | null;
      respondentLastName?: string | null;
      respondentDepartment?: string | null;
      respondentTitle?: string | null;
    }>;
    lastSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me: { __typename?: "User"; id: string; email: string };
};

export type OrgAffiliationTasksQueryVariables = Exact<{
  filters: OrgAffiliationFilterInput;
  page?: InputMaybe<PageInput>;
}>;

export type OrgAffiliationTasksQuery = {
  __typename?: "Query";
  orgAffiliationTasks: {
    __typename?: "PaginatedVerificationTasks";
    count: number;
    items: Array<{
      __typename?: "VerificationTask";
      id: string;
      notes?: string | null;
      personNpi?: string | null;
      completed: boolean;
      updated: string;
      initialData: {
        __typename?: "AffiliationTaskData";
        institution: {
          __typename?: "OrganizationTaskData";
          name: string;
          npi?: string | null;
          masterOrgId: string;
          taxId?: string | null;
          faxNumber?: string | null;
          orgType: {
            __typename?: "OrgType";
            level1: string;
            level2?: string | null;
            level3?: string | null;
          };
          address: {
            __typename?: "TaskAddress";
            line1: string;
            line2?: string | null;
            locality: string;
            postalCode: string;
            region: string;
          };
          phone?: {
            __typename?: "PhoneNumber";
            number: string;
            extension?: string | null;
          } | null;
        };
        person: {
          __typename?: "PersonTaskData";
          firstName: string;
          lastName: string;
          npi: string;
          middleName?: string | null;
          email?: string | null;
          designations: Array<string>;
          specialties: Array<string>;
          phone?: {
            __typename?: "PhoneNumber";
            number: string;
            extension?: string | null;
          } | null;
        };
      };
      completedBy?: { __typename?: "User"; id: string; email: string } | null;
      data: {
        __typename?: "VerificationData";
        affiliationId?: string | null;
        phoneNumber?: string | null;
        orgName?: string | null;
        orgAddressLine1?: string | null;
        orgAddressLine2?: string | null;
        orgAddressLocality?: string | null;
        orgAddressRegion?: string | null;
        orgAddressPostalCode?: string | null;
        orgNpi?: string | null;
        orgTaxId?: string | null;
        affiliationStatus?: AffiliationStatus | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        nickname?: string | null;
        department?: string | null;
        departmentRole?: string | null;
        email?: string | null;
        isAcceptNewPatients?: boolean | null;
        directFaxNumber?: string | null;
        spokenLanguages?: Array<string> | null;
        profileUrl?: string | null;
        listingUrl?: string | null;
        personalPhoneNumber?: string | null;
        directPhoneNumber?: string | null;
        appointmentPhoneNumber?: string | null;
        appointmentPhoneNumberExt?: string | null;
        orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
        operationHours?: Array<{
          __typename?: "OperationHours";
          open: string;
          close: string;
          day: string;
          type: OperationHourType;
        }> | null;
      };
      phoneNumbers: Array<{
        __typename?: "TaskPhoneNumber";
        number: string;
        extension?: string | null;
        type?: PhoneNumberType | null;
        called: boolean;
        callStatus?: TaskStatusReason | null;
        respondentFirstName?: string | null;
        respondentLastName?: string | null;
        respondentDepartment?: string | null;
        respondentTitle?: string | null;
      }>;
      lastSession?: {
        __typename?: "CallTaskSession";
        id: string;
        status: string;
        statusReason: string;
        created: string;
        updated: string;
        notes?: string | null;
        lastUpdate?: {
          __typename?: "SessionUpdate";
          created: string;
          createdBy?: { __typename?: "User"; id: string; email: string } | null;
        } | null;
      } | null;
    }>;
  };
};

export type PersonAffiliationTasksQueryVariables = Exact<{
  npi: Scalars["String"];
  completed?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PersonAffiliationTasksQuery = {
  __typename?: "Query";
  personAffiliationTasks: Array<{
    __typename?: "VerificationTask";
    id: string;
    notes?: string | null;
    personNpi?: string | null;
    completed: boolean;
    updated: string;
    initialData: {
      __typename?: "AffiliationTaskData";
      institution: {
        __typename?: "OrganizationTaskData";
        name: string;
        npi?: string | null;
        masterOrgId: string;
        taxId?: string | null;
        faxNumber?: string | null;
        orgType: {
          __typename?: "OrgType";
          level1: string;
          level2?: string | null;
          level3?: string | null;
        };
        address: {
          __typename?: "TaskAddress";
          line1: string;
          line2?: string | null;
          locality: string;
          postalCode: string;
          region: string;
        };
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
      person: {
        __typename?: "PersonTaskData";
        firstName: string;
        lastName: string;
        npi: string;
        middleName?: string | null;
        email?: string | null;
        designations: Array<string>;
        specialties: Array<string>;
        phone?: {
          __typename?: "PhoneNumber";
          number: string;
          extension?: string | null;
        } | null;
      };
    };
    completedBy?: { __typename?: "User"; id: string; email: string } | null;
    data: {
      __typename?: "VerificationData";
      affiliationId?: string | null;
      phoneNumber?: string | null;
      orgName?: string | null;
      orgAddressLine1?: string | null;
      orgAddressLine2?: string | null;
      orgAddressLocality?: string | null;
      orgAddressRegion?: string | null;
      orgAddressPostalCode?: string | null;
      orgNpi?: string | null;
      orgTaxId?: string | null;
      affiliationStatus?: AffiliationStatus | null;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      nickname?: string | null;
      department?: string | null;
      departmentRole?: string | null;
      email?: string | null;
      isAcceptNewPatients?: boolean | null;
      directFaxNumber?: string | null;
      spokenLanguages?: Array<string> | null;
      profileUrl?: string | null;
      listingUrl?: string | null;
      personalPhoneNumber?: string | null;
      directPhoneNumber?: string | null;
      appointmentPhoneNumber?: string | null;
      appointmentPhoneNumberExt?: string | null;
      orgType: { __typename?: "InstitutionOrgType"; orgTypeLevel1: string };
      operationHours?: Array<{
        __typename?: "OperationHours";
        open: string;
        close: string;
        day: string;
        type: OperationHourType;
      }> | null;
    };
    phoneNumbers: Array<{
      __typename?: "TaskPhoneNumber";
      number: string;
      extension?: string | null;
      type?: PhoneNumberType | null;
      called: boolean;
      callStatus?: TaskStatusReason | null;
      respondentFirstName?: string | null;
      respondentLastName?: string | null;
      respondentDepartment?: string | null;
      respondentTitle?: string | null;
    }>;
    lastSession?: {
      __typename?: "CallTaskSession";
      id: string;
      status: string;
      statusReason: string;
      created: string;
      updated: string;
      notes?: string | null;
      lastUpdate?: {
        __typename?: "SessionUpdate";
        created: string;
        createdBy?: { __typename?: "User"; id: string; email: string } | null;
      } | null;
    } | null;
  }>;
};

export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
  }
`;
export const OperationHoursFragmentDoc = gql`
  fragment OperationHours on OperationHours {
    open
    close
    day
    type
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    id
    line1
    city
    state
    zip
  }
`;
export const TaskPhoneNumberFragmentDoc = gql`
  fragment TaskPhoneNumber on TaskPhoneNumber {
    number
    extension
    type
    called
    callStatus
    respondentFirstName
    respondentLastName
    respondentDepartment
    respondentTitle
  }
`;
export const CallTaskSessionFragmentDoc = gql`
  fragment CallTaskSession on CallTaskSession {
    id
    status
    statusReason
    created
    updated
    notes
    lastUpdate {
      created
      createdBy {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export const OrganizationTaskFragmentDoc = gql`
  fragment OrganizationTask on OrganizationTask {
    id
    completed
    notes
    orgMasterId
    updated
    completedBy {
      ...User
    }
    data {
      orgName
      orgAddressLine1
      orgAddressLine2
      orgAddressLocality
      orgAddressRegion
      orgAddressPostalCode
      orgFaxNumber
      domainName
      affiliationVerificationUrl
      affiliationVerificationUrlDetail
      operationHours {
        ...OperationHours
      }
    }
    organization {
      type
      name
      institutionAddress {
        address {
          ...Address
        }
      }
    }
    phoneNumbers {
      ...TaskPhoneNumber
    }
    lastSession {
      ...CallTaskSession
    }
  }
  ${UserFragmentDoc}
  ${OperationHoursFragmentDoc}
  ${AddressFragmentDoc}
  ${TaskPhoneNumberFragmentDoc}
  ${CallTaskSessionFragmentDoc}
`;
export const TaskSessionFragmentDoc = gql`
  fragment TaskSession on TaskSession {
    id
    notes
    status
    statusReason
    created
    updated
  }
`;
export const ValidationResultDtoFragmentDoc = gql`
  fragment ValidationResultDto on ValidationResultDto {
    field
    code
    message
  }
`;
export const PersonFragmentDoc = gql`
  fragment Person on Person {
    id
    firstName
    lastName
    middleName
    npi
    phoneNumber
    designation
    email
    specialties
  }
`;
export const TaskAddressFragmentDoc = gql`
  fragment TaskAddress on TaskAddress {
    line1
    line2
    locality
    postalCode
    region
  }
`;
export const VerificationDataFragmentDoc = gql`
  fragment VerificationData on VerificationData {
    affiliationId
    phoneNumber
    orgName
    orgAddressLine1
    orgAddressLine2
    orgAddressLocality
    orgAddressRegion
    orgAddressPostalCode
    orgNpi
    orgTaxId
    orgType {
      orgTypeLevel1
    }
    affiliationStatus
    firstName
    lastName
    middleName
    nickname
    department
    departmentRole
    email
    isAcceptNewPatients
    directFaxNumber
    spokenLanguages
    operationHours {
      ...OperationHours
    }
    profileUrl
    listingUrl
    personalPhoneNumber
    directPhoneNumber
    appointmentPhoneNumber
    appointmentPhoneNumberExt
  }
  ${OperationHoursFragmentDoc}
`;
export const VerificationTaskFragmentDoc = gql`
  fragment VerificationTask on VerificationTask {
    id
    notes
    personNpi
    completed
    updated
    initialData {
      institution {
        name
        npi
        masterOrgId
        taxId
        faxNumber
        orgType {
          level1
          level2
          level3
        }
        address {
          ...TaskAddress
        }
        phone {
          number
          extension
        }
      }
      person {
        firstName
        lastName
        npi
        middleName
        phone {
          number
          extension
        }
        email
        designations
        specialties
      }
    }
    completedBy {
      ...User
    }
    data {
      ...VerificationData
    }
    phoneNumbers {
      ...TaskPhoneNumber
    }
    lastSession {
      ...CallTaskSession
    }
  }
  ${TaskAddressFragmentDoc}
  ${UserFragmentDoc}
  ${VerificationDataFragmentDoc}
  ${TaskPhoneNumberFragmentDoc}
  ${CallTaskSessionFragmentDoc}
`;
export const VerificationFragmentDoc = gql`
  fragment Verification on Verification {
    person {
      ...Person
    }
    tasks {
      ...VerificationTask
    }
  }
  ${PersonFragmentDoc}
  ${VerificationTaskFragmentDoc}
`;
export const CreateAffiliationTaskSessionsDocument = gql`
  mutation createAffiliationTaskSessions(
    $verificationTaskId: String!
    $taskSessions: [AffiliationCallSessionInput!]!
  ) {
    createAffiliationTaskSessions(
      input: {
        verificationTaskId: $verificationTaskId
        taskSessions: $taskSessions
      }
    ) {
      code
      success
      message
      task {
        ...VerificationTask
      }
      errors {
        ...ValidationResultDto
      }
    }
  }
  ${VerificationTaskFragmentDoc}
  ${ValidationResultDtoFragmentDoc}
`;
export type CreateAffiliationTaskSessionsMutationFn = Apollo.MutationFunction<
  CreateAffiliationTaskSessionsMutation,
  CreateAffiliationTaskSessionsMutationVariables
>;

/**
 * __useCreateAffiliationTaskSessionsMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationTaskSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationTaskSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationTaskSessionsMutation, { data, loading, error }] = useCreateAffiliationTaskSessionsMutation({
 *   variables: {
 *      verificationTaskId: // value for 'verificationTaskId'
 *      taskSessions: // value for 'taskSessions'
 *   },
 * });
 */
export function useCreateAffiliationTaskSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAffiliationTaskSessionsMutation,
    CreateAffiliationTaskSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAffiliationTaskSessionsMutation,
    CreateAffiliationTaskSessionsMutationVariables
  >(CreateAffiliationTaskSessionsDocument, options);
}
export type CreateAffiliationTaskSessionsMutationHookResult = ReturnType<
  typeof useCreateAffiliationTaskSessionsMutation
>;
export type CreateAffiliationTaskSessionsMutationResult =
  Apollo.MutationResult<CreateAffiliationTaskSessionsMutation>;
export type CreateAffiliationTaskSessionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAffiliationTaskSessionsMutation,
    CreateAffiliationTaskSessionsMutationVariables
  >;
export const CreateOrganizationTaskSessionDocument = gql`
  mutation createOrganizationTaskSession(
    $verificationTaskId: String!
    $taskSessions: [OrganizationCallSessionInput!]!
  ) {
    createOrganizationTaskSessions(
      input: {
        verificationTaskId: $verificationTaskId
        taskSessions: $taskSessions
      }
    ) {
      code
      success
      message
      errors {
        ...ValidationResultDto
      }
      task {
        ...OrganizationTask
      }
    }
  }
  ${ValidationResultDtoFragmentDoc}
  ${OrganizationTaskFragmentDoc}
`;
export type CreateOrganizationTaskSessionMutationFn = Apollo.MutationFunction<
  CreateOrganizationTaskSessionMutation,
  CreateOrganizationTaskSessionMutationVariables
>;

/**
 * __useCreateOrganizationTaskSessionMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationTaskSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationTaskSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationTaskSessionMutation, { data, loading, error }] = useCreateOrganizationTaskSessionMutation({
 *   variables: {
 *      verificationTaskId: // value for 'verificationTaskId'
 *      taskSessions: // value for 'taskSessions'
 *   },
 * });
 */
export function useCreateOrganizationTaskSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationTaskSessionMutation,
    CreateOrganizationTaskSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationTaskSessionMutation,
    CreateOrganizationTaskSessionMutationVariables
  >(CreateOrganizationTaskSessionDocument, options);
}
export type CreateOrganizationTaskSessionMutationHookResult = ReturnType<
  typeof useCreateOrganizationTaskSessionMutation
>;
export type CreateOrganizationTaskSessionMutationResult =
  Apollo.MutationResult<CreateOrganizationTaskSessionMutation>;
export type CreateOrganizationTaskSessionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrganizationTaskSessionMutation,
    CreateOrganizationTaskSessionMutationVariables
  >;
export const ExchangeTokenDocument = gql`
  mutation exchangeToken($oneTimeToken: String!) {
    exchangeToken(input: { oneTimeToken: $oneTimeToken }) {
      code
      success
      message
      jwt
      user {
        ...User
      }
      errors {
        ...ValidationResultDto
      }
    }
  }
  ${UserFragmentDoc}
  ${ValidationResultDtoFragmentDoc}
`;
export type ExchangeTokenMutationFn = Apollo.MutationFunction<
  ExchangeTokenMutation,
  ExchangeTokenMutationVariables
>;

/**
 * __useExchangeTokenMutation__
 *
 * To run a mutation, you first call `useExchangeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeTokenMutation, { data, loading, error }] = useExchangeTokenMutation({
 *   variables: {
 *      oneTimeToken: // value for 'oneTimeToken'
 *   },
 * });
 */
export function useExchangeTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangeTokenMutation,
    ExchangeTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExchangeTokenMutation,
    ExchangeTokenMutationVariables
  >(ExchangeTokenDocument, options);
}
export type ExchangeTokenMutationHookResult = ReturnType<
  typeof useExchangeTokenMutation
>;
export type ExchangeTokenMutationResult =
  Apollo.MutationResult<ExchangeTokenMutation>;
export type ExchangeTokenMutationOptions = Apollo.BaseMutationOptions<
  ExchangeTokenMutation,
  ExchangeTokenMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout {
      code
      success
      message
      errors {
        ...ValidationResultDto
      }
    }
  }
  ${ValidationResultDtoFragmentDoc}
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RequestTokenDocument = gql`
  mutation requestToken($email: String!) {
    requestToken(input: { email: $email }) {
      code
      errors {
        ...ValidationResultDto
      }
      message
      success
    }
  }
  ${ValidationResultDtoFragmentDoc}
`;
export type RequestTokenMutationFn = Apollo.MutationFunction<
  RequestTokenMutation,
  RequestTokenMutationVariables
>;

/**
 * __useRequestTokenMutation__
 *
 * To run a mutation, you first call `useRequestTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTokenMutation, { data, loading, error }] = useRequestTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTokenMutation,
    RequestTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestTokenMutation,
    RequestTokenMutationVariables
  >(RequestTokenDocument, options);
}
export type RequestTokenMutationHookResult = ReturnType<
  typeof useRequestTokenMutation
>;
export type RequestTokenMutationResult =
  Apollo.MutationResult<RequestTokenMutation>;
export type RequestTokenMutationOptions = Apollo.BaseMutationOptions<
  RequestTokenMutation,
  RequestTokenMutationVariables
>;
export const UpdateAffiliationTaskSessionDocument = gql`
  mutation updateAffiliationTaskSession(
    $id: String!
    $update: AffiliationSessionUpdateInput!
  ) {
    updateAffiliationTaskSession(input: { id: $id, update: $update }) {
      code
      success
      message
      callSession {
        ...CallTaskSession
      }
      errors {
        ...ValidationResultDto
      }
    }
  }
  ${CallTaskSessionFragmentDoc}
  ${ValidationResultDtoFragmentDoc}
`;
export type UpdateAffiliationTaskSessionMutationFn = Apollo.MutationFunction<
  UpdateAffiliationTaskSessionMutation,
  UpdateAffiliationTaskSessionMutationVariables
>;

/**
 * __useUpdateAffiliationTaskSessionMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliationTaskSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliationTaskSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliationTaskSessionMutation, { data, loading, error }] = useUpdateAffiliationTaskSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateAffiliationTaskSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAffiliationTaskSessionMutation,
    UpdateAffiliationTaskSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAffiliationTaskSessionMutation,
    UpdateAffiliationTaskSessionMutationVariables
  >(UpdateAffiliationTaskSessionDocument, options);
}
export type UpdateAffiliationTaskSessionMutationHookResult = ReturnType<
  typeof useUpdateAffiliationTaskSessionMutation
>;
export type UpdateAffiliationTaskSessionMutationResult =
  Apollo.MutationResult<UpdateAffiliationTaskSessionMutation>;
export type UpdateAffiliationTaskSessionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAffiliationTaskSessionMutation,
    UpdateAffiliationTaskSessionMutationVariables
  >;
export const UpdateOrganizationTaskSessionDocument = gql`
  mutation updateOrganizationTaskSession(
    $id: String!
    $update: OrganizationSessionUpdateInput!
  ) {
    updateOrganizationTaskSession(input: { id: $id, update: $update }) {
      code
      success
      message
      errors {
        ...ValidationResultDto
      }
      callSession {
        ...CallTaskSession
      }
    }
  }
  ${ValidationResultDtoFragmentDoc}
  ${CallTaskSessionFragmentDoc}
`;
export type UpdateOrganizationTaskSessionMutationFn = Apollo.MutationFunction<
  UpdateOrganizationTaskSessionMutation,
  UpdateOrganizationTaskSessionMutationVariables
>;

/**
 * __useUpdateOrganizationTaskSessionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationTaskSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationTaskSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationTaskSessionMutation, { data, loading, error }] = useUpdateOrganizationTaskSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateOrganizationTaskSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationTaskSessionMutation,
    UpdateOrganizationTaskSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationTaskSessionMutation,
    UpdateOrganizationTaskSessionMutationVariables
  >(UpdateOrganizationTaskSessionDocument, options);
}
export type UpdateOrganizationTaskSessionMutationHookResult = ReturnType<
  typeof useUpdateOrganizationTaskSessionMutation
>;
export type UpdateOrganizationTaskSessionMutationResult =
  Apollo.MutationResult<UpdateOrganizationTaskSessionMutation>;
export type UpdateOrganizationTaskSessionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrganizationTaskSessionMutation,
    UpdateOrganizationTaskSessionMutationVariables
  >;
export const AllowedLanguagesDocument = gql`
  query allowedLanguages {
    allowedLanguages {
      value
      label
    }
  }
`;

/**
 * __useAllowedLanguagesQuery__
 *
 * To run a query within a React component, call `useAllowedLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowedLanguagesQuery,
    AllowedLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllowedLanguagesQuery, AllowedLanguagesQueryVariables>(
    AllowedLanguagesDocument,
    options
  );
}
export function useAllowedLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowedLanguagesQuery,
    AllowedLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowedLanguagesQuery,
    AllowedLanguagesQueryVariables
  >(AllowedLanguagesDocument, options);
}
export type AllowedLanguagesQueryHookResult = ReturnType<
  typeof useAllowedLanguagesQuery
>;
export type AllowedLanguagesLazyQueryHookResult = ReturnType<
  typeof useAllowedLanguagesLazyQuery
>;
export type AllowedLanguagesQueryResult = Apollo.QueryResult<
  AllowedLanguagesQuery,
  AllowedLanguagesQueryVariables
>;
export const AllowedStatesDocument = gql`
  query allowedStates {
    allowedStates {
      value
      label
    }
  }
`;

/**
 * __useAllowedStatesQuery__
 *
 * To run a query within a React component, call `useAllowedStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedStatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowedStatesQuery,
    AllowedStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllowedStatesQuery, AllowedStatesQueryVariables>(
    AllowedStatesDocument,
    options
  );
}
export function useAllowedStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowedStatesQuery,
    AllowedStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllowedStatesQuery, AllowedStatesQueryVariables>(
    AllowedStatesDocument,
    options
  );
}
export type AllowedStatesQueryHookResult = ReturnType<
  typeof useAllowedStatesQuery
>;
export type AllowedStatesLazyQueryHookResult = ReturnType<
  typeof useAllowedStatesLazyQuery
>;
export type AllowedStatesQueryResult = Apollo.QueryResult<
  AllowedStatesQuery,
  AllowedStatesQueryVariables
>;
export const AutocompleteAddressDocument = gql`
  query autocompleteAddress($input: AutocompleteAddressInput!) {
    autocompleteAddress(input: $input) {
      code
      success
      addresses {
        city
        houseNumber
        label
        postalCode
        stateCode
        resultType
        street
        addressLine1
        labelHighlights {
          start
          end
        }
      }
    }
  }
`;

/**
 * __useAutocompleteAddressQuery__
 *
 * To run a query within a React component, call `useAutocompleteAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutocompleteAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteAddressQuery,
    AutocompleteAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteAddressQuery,
    AutocompleteAddressQueryVariables
  >(AutocompleteAddressDocument, options);
}
export function useAutocompleteAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteAddressQuery,
    AutocompleteAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteAddressQuery,
    AutocompleteAddressQueryVariables
  >(AutocompleteAddressDocument, options);
}
export type AutocompleteAddressQueryHookResult = ReturnType<
  typeof useAutocompleteAddressQuery
>;
export type AutocompleteAddressLazyQueryHookResult = ReturnType<
  typeof useAutocompleteAddressLazyQuery
>;
export type AutocompleteAddressQueryResult = Apollo.QueryResult<
  AutocompleteAddressQuery,
  AutocompleteAddressQueryVariables
>;
export const FindOrganizationTaskDocument = gql`
  query findOrganizationTask($orgId: String!) {
    findOrganizationTask(input: { orgId: $orgId }) {
      ...OrganizationTask
    }
  }
  ${OrganizationTaskFragmentDoc}
`;

/**
 * __useFindOrganizationTaskQuery__
 *
 * To run a query within a React component, call `useFindOrganizationTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationTaskQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useFindOrganizationTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOrganizationTaskQuery,
    FindOrganizationTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindOrganizationTaskQuery,
    FindOrganizationTaskQueryVariables
  >(FindOrganizationTaskDocument, options);
}
export function useFindOrganizationTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOrganizationTaskQuery,
    FindOrganizationTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindOrganizationTaskQuery,
    FindOrganizationTaskQueryVariables
  >(FindOrganizationTaskDocument, options);
}
export type FindOrganizationTaskQueryHookResult = ReturnType<
  typeof useFindOrganizationTaskQuery
>;
export type FindOrganizationTaskLazyQueryHookResult = ReturnType<
  typeof useFindOrganizationTaskLazyQuery
>;
export type FindOrganizationTaskQueryResult = Apollo.QueryResult<
  FindOrganizationTaskQuery,
  FindOrganizationTaskQueryVariables
>;
export const FindPersonTaskDocument = gql`
  query findPersonTask($npi: String!) {
    findPersonTask(input: { npi: $npi }) {
      id
      person {
        ...Person
      }
    }
  }
  ${PersonFragmentDoc}
`;

/**
 * __useFindPersonTaskQuery__
 *
 * To run a query within a React component, call `useFindPersonTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPersonTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPersonTaskQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useFindPersonTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPersonTaskQuery,
    FindPersonTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPersonTaskQuery, FindPersonTaskQueryVariables>(
    FindPersonTaskDocument,
    options
  );
}
export function useFindPersonTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPersonTaskQuery,
    FindPersonTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPersonTaskQuery, FindPersonTaskQueryVariables>(
    FindPersonTaskDocument,
    options
  );
}
export type FindPersonTaskQueryHookResult = ReturnType<
  typeof useFindPersonTaskQuery
>;
export type FindPersonTaskLazyQueryHookResult = ReturnType<
  typeof useFindPersonTaskLazyQuery
>;
export type FindPersonTaskQueryResult = Apollo.QueryResult<
  FindPersonTaskQuery,
  FindPersonTaskQueryVariables
>;
export const GetVerificationTaskDocument = gql`
  query getVerificationTask($id: String!) {
    getVerificationTask(input: { id: $id }) {
      ...VerificationTask
    }
  }
  ${VerificationTaskFragmentDoc}
`;

/**
 * __useGetVerificationTaskQuery__
 *
 * To run a query within a React component, call `useGetVerificationTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVerificationTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVerificationTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVerificationTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVerificationTaskQuery,
    GetVerificationTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVerificationTaskQuery,
    GetVerificationTaskQueryVariables
  >(GetVerificationTaskDocument, options);
}
export function useGetVerificationTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVerificationTaskQuery,
    GetVerificationTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVerificationTaskQuery,
    GetVerificationTaskQueryVariables
  >(GetVerificationTaskDocument, options);
}
export type GetVerificationTaskQueryHookResult = ReturnType<
  typeof useGetVerificationTaskQuery
>;
export type GetVerificationTaskLazyQueryHookResult = ReturnType<
  typeof useGetVerificationTaskLazyQuery
>;
export type GetVerificationTaskQueryResult = Apollo.QueryResult<
  GetVerificationTaskQuery,
  GetVerificationTaskQueryVariables
>;
export const MeDocument = gql`
  query me {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OrgAffiliationTasksDocument = gql`
  query orgAffiliationTasks(
    $filters: OrgAffiliationFilterInput!
    $page: PageInput
  ) {
    orgAffiliationTasks(input: { filters: $filters, page: $page }) {
      count
      items {
        ...VerificationTask
      }
    }
  }
  ${VerificationTaskFragmentDoc}
`;

/**
 * __useOrgAffiliationTasksQuery__
 *
 * To run a query within a React component, call `useOrgAffiliationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgAffiliationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgAffiliationTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrgAffiliationTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgAffiliationTasksQuery,
    OrgAffiliationTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgAffiliationTasksQuery,
    OrgAffiliationTasksQueryVariables
  >(OrgAffiliationTasksDocument, options);
}
export function useOrgAffiliationTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgAffiliationTasksQuery,
    OrgAffiliationTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgAffiliationTasksQuery,
    OrgAffiliationTasksQueryVariables
  >(OrgAffiliationTasksDocument, options);
}
export type OrgAffiliationTasksQueryHookResult = ReturnType<
  typeof useOrgAffiliationTasksQuery
>;
export type OrgAffiliationTasksLazyQueryHookResult = ReturnType<
  typeof useOrgAffiliationTasksLazyQuery
>;
export type OrgAffiliationTasksQueryResult = Apollo.QueryResult<
  OrgAffiliationTasksQuery,
  OrgAffiliationTasksQueryVariables
>;
export const PersonAffiliationTasksDocument = gql`
  query personAffiliationTasks($npi: String!, $completed: Boolean) {
    personAffiliationTasks(input: { npi: $npi, completed: $completed }) {
      ...VerificationTask
    }
  }
  ${VerificationTaskFragmentDoc}
`;

/**
 * __usePersonAffiliationTasksQuery__
 *
 * To run a query within a React component, call `usePersonAffiliationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonAffiliationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonAffiliationTasksQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function usePersonAffiliationTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonAffiliationTasksQuery,
    PersonAffiliationTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PersonAffiliationTasksQuery,
    PersonAffiliationTasksQueryVariables
  >(PersonAffiliationTasksDocument, options);
}
export function usePersonAffiliationTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonAffiliationTasksQuery,
    PersonAffiliationTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PersonAffiliationTasksQuery,
    PersonAffiliationTasksQueryVariables
  >(PersonAffiliationTasksDocument, options);
}
export type PersonAffiliationTasksQueryHookResult = ReturnType<
  typeof usePersonAffiliationTasksQuery
>;
export type PersonAffiliationTasksLazyQueryHookResult = ReturnType<
  typeof usePersonAffiliationTasksLazyQuery
>;
export type PersonAffiliationTasksQueryResult = Apollo.QueryResult<
  PersonAffiliationTasksQuery,
  PersonAffiliationTasksQueryVariables
>;
