import { memo, useCallback } from "react";

import { TAddressOption, AutocompleteField, TAutocomplete } from "Shared";

import { FormikAutocompleteAddressProps } from ".";

type FormikAutocompleteAddressComponentProps =
  FormikAutocompleteAddressProps & {
    setFieldValue: (value: string) => void;
    value: string;
    onBlur: TAutocomplete["onBlur"];
  };

export const FormikAutocompleteAddressComponent = memo(
  (props: FormikAutocompleteAddressComponentProps): JSX.Element => {
    const { value, options, setFieldValue, onAddressSelect } = props;

    const initialLabel =
      options.find((option) => option.value === value)?.label || value || "";

    const autocompleteValue: TAddressOption = {
      value: value || "",
      label: initialLabel
    };

    const autocompleteOptions = [autocompleteValue, ...options];

    const handleChangeOption = useCallback(
      (_: React.ChangeEvent<Record<string, unknown>>, options) => {
        const option = options as TAddressOption;
        const value = `${option?.formData?.houseNumber || ""} ${
          option?.formData?.street || ""
        }`;
        if (value && onAddressSelect) {
          onAddressSelect(option);
          setFieldValue(value);
        }
      },
      [setFieldValue, onAddressSelect]
    );

    const handleChangeTextField = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setFieldValue(value);
      },
      [setFieldValue]
    );

    const passedProps = {
      ...props,
      options: autocompleteOptions,
      freeSolo: true,
      autocompleteValue,
      handleChange: handleChangeOption,
      handleChangeTextField
    };

    return <AutocompleteField {...passedProps} />;
  }
);
