import omit from "lodash/omit";
import { useCallback } from "react";

import {
  prepareFormData,
  TOrgVerificationInitialValues,
  transformInitialValuesTOHoursOfOperation
} from "Shared";
import {
  OrganizationSessionUpdateInput,
  TaskStatus
} from "Shared/graphql/generated";
import {
  canComplete,
  canSaveAndDefer,
  getOrgValidationVariables
} from "OrganizationValidation/helpers";
import { useOrgDataContext } from "OrganizationValidation/context";

import { useUpdateOrganizationTaskSession } from "./useUpdateOrganizationTaskSession";
import { useCreateOrganizationTaskSessions } from "./useCreateOrganizationTaskSessions";

export type TUseOrganizationActionsProps = {
  initialValues: TOrgVerificationInitialValues;
  updatedValues: TOrgVerificationInitialValues;
  verificationTaskId: string;
  isValid: boolean;
  sessionId?: string;
  isAutocompleted: boolean;
};

export const useOrganizationActions = ({
  initialValues,
  updatedValues,
  verificationTaskId,
  isValid,
  sessionId,
  isAutocompleted
}: TUseOrganizationActionsProps) => {
  const { createOrgTaskSession } = useCreateOrganizationTaskSessions();
  const { isUpdating, updateOrgTask } = useUpdateOrganizationTaskSession();
  const { setOrgData } = useOrgDataContext();

  const isSaveAndDeferEnabled =
    isValid &&
    updatedValues.phoneNumbers &&
    canSaveAndDefer(updatedValues.phoneNumbers);
  const isCompleteButtonEnabled =
    isValid &&
    updatedValues.phoneNumbers &&
    canComplete(updatedValues.phoneNumbers);
  const isUpdateButtonEnabled = isValid && !isUpdating;

  const onSaveAndDefer = useCallback(
    async (passedValues?: TOrgVerificationInitialValues) => {
      const changedValues = prepareFormData(
        passedValues || updatedValues,
        initialValues
      );

      const variables = getOrgValidationVariables({
        formData: {
          phoneNumbers: updatedValues.phoneNumbers,
          notes: updatedValues.notes,
          ...changedValues
        },
        verificationTaskId,
        status: TaskStatus.Deferred,
        isAutocompleted
      });

      if (variables) {
        await createOrgTaskSession({ variables, isComplete: false });
        setOrgData(variables.taskSessions?.[0].update);
      }
    },
    [
      createOrgTaskSession,
      initialValues,
      setOrgData,
      updatedValues,
      verificationTaskId,
      isAutocompleted
    ]
  );

  const onComplete = useCallback(
    async (passedValues?: TOrgVerificationInitialValues) => {
      const changedValues = prepareFormData(
        passedValues || updatedValues,
        initialValues
      );

      const variables = getOrgValidationVariables({
        formData: {
          phoneNumbers: updatedValues.phoneNumbers,
          notes: updatedValues.notes,
          ...changedValues
        },
        verificationTaskId,
        status: TaskStatus.Complete,
        isAutocompleted
      });
      if (variables) {
        await createOrgTaskSession({ variables, isComplete: true });
        setOrgData(variables.taskSessions[0].update);
      }
    },
    [
      createOrgTaskSession,
      initialValues,
      setOrgData,
      updatedValues,
      verificationTaskId,
      isAutocompleted
    ]
  );

  const onUpdate = useCallback(
    async (passedValues?: TOrgVerificationInitialValues) => {
      const newValues = prepareFormData(
        passedValues || updatedValues,
        initialValues
      );

      if (sessionId) {
        const newValuesWithoutTransformation = omit(
          newValues,
          "phoneNumbers",
          "hoursOfOperationOrganization"
        );

        const operationHours = transformInitialValuesTOHoursOfOperation(
          [],
          newValues.hoursOfOperationOrganization
        );
        const updateData: OrganizationSessionUpdateInput = {
          ...newValuesWithoutTransformation,
          operationHours,
          isAutocompleted
        };

        await updateOrgTask({
          id: sessionId,
          update: updateData
        });
        setOrgData(updateData);
      }
    },
    [
      initialValues,
      isAutocompleted,
      sessionId,
      setOrgData,
      updateOrgTask,
      updatedValues
    ]
  );

  return {
    onSaveAndDefer,
    onUpdate,
    onComplete,
    isSaveAndDeferEnabled,
    isCompleteButtonEnabled,
    isUpdateButtonEnabled
  };
};
