import * as Yup from "yup";
import { toNumber } from "lodash";

type TOperationHoursContext = {
  parent: {
    open: string;
    close: string;
    day: boolean[];
  };
};

export const hoursOfOperationValidation = (name: string) =>
  Yup.array().of(
    Yup.object().shape({
      open: Yup.string().test(
        name,
        "Start hours are higher than Close ones",
        (value: string | undefined, context: TOperationHoursContext) => {
          const close: string = context.parent.close;

          const result =
            toNumber(value?.replace(":", "")) <=
            toNumber(close?.replace(":", ""));

          return result;
        }
      )
    })
  );
