import { debounce } from "lodash";
import { useEffect, useRef } from "react";

import { useAutocompleteAddressLazyQuery } from "Shared/graphql/generated";

export const DEBOUNCE_TEXT_INPUT_TIME = 600;

export const DEBOUNCE_TEXT_INPUT_MAX_TIME = 1200;

export const useDebouncedGeocode = (value: string) => {
  const [geocode, { data }] = useAutocompleteAddressLazyQuery({
    fetchPolicy: "no-cache"
  });

  const debouncedGetNewGeocode = useRef(
    debounce(
      (value) => {
        void geocode({
          variables: {
            input: {
              limit: 4,
              value,
              resultType: "houseNumber"
            }
          }
        });
      },
      DEBOUNCE_TEXT_INPUT_TIME,
      {
        maxWait: DEBOUNCE_TEXT_INPUT_MAX_TIME
      }
    )
  );

  useEffect(() => {
    if (value) {
      debouncedGetNewGeocode.current(value);
    }
  }, [value]);

  return {
    data
  };
};
