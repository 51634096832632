import {
  useGetVerificationTaskQuery,
  GetVerificationTaskQueryVariables
} from "Shared/graphql/generated";

import { getAffiliationFormInitialValues } from "../helpers";

export const useGetVerificationTask = (
  variables: GetVerificationTaskQueryVariables
) => {
  const { data, loading, refetch } = useGetVerificationTaskQuery({
    variables,
    fetchPolicy: "no-cache"
  });

  const {
    data: taskData,
    completed,
    updated,
    completedBy,
    phoneNumbers,
    initialData,
    lastSession
  } = data?.getVerificationTask || {};

  const institution = initialData?.institution;
  const { name } = institution || {};
  const { address } = institution || {};
  const orgType = institution?.orgType?.level1;

  const validationFormInitialValues = getAffiliationFormInitialValues({
    taskData,
    phoneNumbers,
    notes: lastSession?.notes
  });
  return {
    completed,
    phoneNumbers,
    name,
    address,
    updated,
    completedBy,
    orgType,
    lastSession,
    validationFormInitialValues,
    isLoading: loading,
    refetch
  };
};
