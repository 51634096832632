import * as Yup from "yup";

import { phoneNumberValidation } from "Shared/utils";

export const affiliationsTaskValidation = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email address."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  affiliationStatus: Yup.string().required("Work Status is required."),
  appointmentPhoneNumberExt: Yup.string().matches(
    /^[0-9]+$/,
    "Must be only digits"
  ),
  appointmentPhoneNumber: phoneNumberValidation({
    type: "correctLength",
    field: "Appointment Phone"
  }).when("appointmentPhoneNumberExt", {
    is: (val: string) => val,
    then: Yup.string().required("Appointment Phone is required"),
    otherwise: Yup.string()
  })
});
