import { convertEmptyStringToNullOrUndefined } from "./dataTransformation/convertEmptyStringToNullOrUndefined";
import { findDifference } from "./findDifference";

/** This function prepare form data based on two steps
 * converting empty string to null or undefined based on previous value
 * finding difference between old form value and new form values
 **/
export const prepareFormData = <T extends Record<string, unknown>>(
  newObject: T,
  prevObject: T
): Partial<T> => {
  const newFormValues = convertEmptyStringToNullOrUndefined(
    newObject,
    prevObject
  );

  return findDifference(prevObject, newFormValues);
};
