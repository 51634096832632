import { useCallback } from "react";

import { usePathTaskHandler, useSnackBarMessage } from "Shared";
import { usePersonAffiliationTasksLazyQuery } from "Shared/graphql/generated";

export const useNpiSearch = () => {
  const { pathTaskHandler } = usePathTaskHandler();
  const { showError } = useSnackBarMessage();
  const [getPersonAffiliationTasks, { loading }] =
    usePersonAffiliationTasksLazyQuery();

  const onSubmit = useCallback(
    async ({ npi }: { npi: string }) => {
      const personAffiliationTasks = await getPersonAffiliationTasks({
        variables: { npi }
      });

      if (personAffiliationTasks.data?.personAffiliationTasks?.length) {
        pathTaskHandler({
          npi,
          taskId: personAffiliationTasks?.data?.personAffiliationTasks[0].id
        });
      } else {
        showError(
          "Invalid NPI / there are no affiliation tasks associated with this NPI"
        );
      }
    },
    [getPersonAffiliationTasks, pathTaskHandler, showError]
  );

  return {
    isLoading: loading,
    onSubmit
  };
};
