import { useFormikContext } from "formik";
import { useCallback, useState } from "react";

import {
  GeocodedAddress,
  useAutocompleteAddressLazyQuery
} from "Shared/graphql/generated";

export type TUseValidationAddressState = Partial<GeocodedAddress> | null;

export const useAddressValidation = () => {
  /** This state provides validated data passed from backend as a recommended address */
  const [validationAddressData, setValidationAddressData] =
    useState<TUseValidationAddressState>(null);

  const { getFieldProps } = useFormikContext();
  const [geocode] = useAutocompleteAddressLazyQuery({
    fetchPolicy: "no-cache"
  });

  const getRecommendedAddress = useCallback(
    async (submittedAddress: string) => {
      const input = {
        limit: 4,
        value: submittedAddress,
        resultType: "houseNumber"
      };

      const response = await geocode({
        variables: {
          input
        }
      });

      return response.data?.autocompleteAddress.addresses?.[0];
    },
    [geocode]
  );

  const validateAddress = useCallback(async () => {
    const { value: orgAddressLine1 } = getFieldProps<string>("orgAddressLine1");
    const { value: orgAddressLocality } =
      getFieldProps<string>("orgAddressLocality");
    const { value: orgAddressRegion } =
      getFieldProps<string>("orgAddressRegion");
    const { value: orgAddressPostalCode } = getFieldProps<string>(
      "orgAddressPostalCode"
    );
    let validated = false;
    const recommendedAddress = await getRecommendedAddress(orgAddressLine1);

    if (recommendedAddress) {
      setValidationAddressData((prev) => ({
        ...(prev || {}),
        ...(recommendedAddress || {})
      }));
      validated = validateAddressFields(
        orgAddressLine1,
        orgAddressLocality,
        orgAddressRegion,
        orgAddressPostalCode,
        recommendedAddress
      );
    } else {
      setValidationAddressData(null);
    }

    return validated;
  }, [getFieldProps, getRecommendedAddress]);

  return { validateAddress, validationAddressData };
};

const validateAddressFields = (
  addressLine1: string,
  city: string,
  state: string,
  zipCode: string,
  recommended: Partial<GeocodedAddress>
) => {
  if (
    addressLine1 !==
    `${recommended?.houseNumber || ""} ${recommended?.street || ""}`
  ) {
    return false;
  } else if (city !== recommended.city) {
    return false;
  } else if (state !== recommended.stateCode) {
    return false;
  } else if (zipCode !== recommended.postalCode) {
    return false;
  } else return true;
};
