import omit from "lodash/omit";

import { listExcludedFormFields } from "Shared";
import {
  TaskStatus,
  CreateAffiliationTaskSessionsInput
} from "Shared/graphql/generated";
import { TValidateTaskFromInitialValues } from "Shared/types";

import { getVerifyTaskVariables } from "./getVerifyTaskVariables";
import { getUpdateData } from "./getUpdatedData";

export type TransformTaskValuesToCreateVariablesInput = {
  newFormValues: TValidateTaskFromInitialValues;
  initFormValues: TValidateTaskFromInitialValues;
  taskId: string;
  status: TaskStatus;
};

/**
 * This function find the diff between initial form values and new form values
 * and returns variables(input) for CompleteVerificationTaskInput mutation
 */
export const transformTaskValuesToCreateVariables = ({
  newFormValues,
  initFormValues,
  taskId,
  status
}: TransformTaskValuesToCreateVariablesInput): CreateAffiliationTaskSessionsInput => {
  const { phoneNumbers, updateData } = getUpdateData(
    omit(newFormValues, listExcludedFormFields),
    initFormValues
  );

  // complete variables
  return getVerifyTaskVariables({
    taskId,
    phoneNumbers,
    updateData,
    status
  });
};
