import { Box, Grid, Typography, useTheme } from "@material-ui/core";

import { getAddressString, Theme, Chip } from "Shared";
import { TaskAddress } from "Shared/graphql/generated";

import {
  VerificationTaskStatus,
  VerificationTaskStatusProps
} from "./VerificationTaskStatus";

type VerificationTaskProps = {
  title?: string | null;
  completed?: boolean;
  isSelected: boolean;
  address?: TaskAddress;
  orgType?: string;
} & VerificationTaskStatusProps;

export const VerificationTask = ({
  title,
  completed = false,
  isSelected = false,
  address,
  status,
  orgType = "Unknown"
}: VerificationTaskProps) => {
  const { palette } = useTheme<Theme>();
  const addressStr = getAddressString(address);

  return (
    <Box
      paddingY={1}
      paddingX={5}
      borderBottom={`1px solid ${palette.grey["300"]}`}
      bgcolor={isSelected ? palette.primary["50"] : ""}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography>{title || "No title"}</Typography>
            <Chip label={orgType} />
            <Box paddingY={1} fontSize="12px">
              <Typography>{addressStr}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <VerificationTaskStatus
            completed={completed}
            isSelected={isSelected}
            status={status}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
