import { ReactNode } from "react";

import { TAddressOption } from "Shared";

// This function is used for making bold some fragments in the text
// using coordinated (highlighting). It slices text (label) on pieces
// and cover some of them into <b> tag

export const getReactNodeWithHighlightedText = (
  option?: TAddressOption
): ReactNode => {
  const result: (ReactNode | string)[] = [];

  if (option) {
    const sliceLabel = (start: string, end?: string): string => {
      if (end) {
        return option.label.slice(parseInt(start, 10), parseInt(end, 10));
      }
      return option.label.slice(parseInt(start, 10));
    };

    // it is an array of coordinates
    const highlights = option.formData?.labelHighlights;

    highlights?.forEach((h, index) => {
      // this case happens for 1st coordinate
      if (index === 0) {
        result.push(sliceLabel("0", h.start));
        result.push(<b key={index}>{sliceLabel(h.start, h.end)}</b>);
        // this case happens when there is only 1 coordinate
        if (highlights.length === 1) {
          result.push(sliceLabel(h.end));
          // this case happens when there are more than 1 coordinates
        } else {
          result.push(sliceLabel(h.end, highlights[index + 1].start));
        }
        // this case works for last coordinate in case there more 1 coordinates
      } else if (index === highlights.length - 1) {
        result.push(
          <b key={index}>
            {option.label.slice(parseInt(h.start, 10), parseInt(h.end, 10))}
          </b>
        );
        result.push(sliceLabel(h.end));
        // this case works for all coordinates that are between 1st and last one
        // it works when there are more than 2 coordinates
      } else {
        result.push(<b key={index}>{sliceLabel(h.start, h.end)}</b>);
        result.push(sliceLabel(h.end, highlights[index + 1].start));
      }
    });
  }

  return <span>{result}</span>;
};
