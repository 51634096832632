import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import { useCallback } from "react";

import { TValidateTaskFromInitialValues, SubmitAddressOption } from "Shared";

type AddressValidationPopupDescriptionProps = {
  recommendedAddress?: string;
  submittedAddress?: TValidateTaskFromInitialValues;
  selectedSubmitAddressOption?: SubmitAddressOption | null;
  setSelectedSubmitAddressOption: (value: SubmitAddressOption) => void;
};

const EMPTY_ADDRESS_TEXT =
  "No address recommendations available. Please go back to review the address submitted.";

const EMPTY_SUBMITTED_ADDRESS_TEXT =
  "No address provided. Please go back and fill some address fields.";

export const AddressValidationPopupDescription = ({
  recommendedAddress,
  submittedAddress,
  selectedSubmitAddressOption,
  setSelectedSubmitAddressOption
}: AddressValidationPopupDescriptionProps): JSX.Element => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedSubmitAddressOption(e.target.value as SubmitAddressOption);
    },
    [setSelectedSubmitAddressOption]
  );

  return (
    <RadioGroup value={selectedSubmitAddressOption} onChange={onChange}>
      <Grid spacing={3} container>
        <Grid item xs={6}>
          <Box padding={recommendedAddress ? 0 : 1}>
            <FormControlLabel
              value={SubmitAddressOption.RECOMMENDED}
              control={recommendedAddress ? <Radio color="primary" /> : <></>}
              label={<Typography variant="h6">Recommended:</Typography>}
            />
          </Box>
          <Box marginLeft={recommendedAddress ? 4 : 0}>
            <Typography>{recommendedAddress || EMPTY_ADDRESS_TEXT}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={SubmitAddressOption.SUBMITTED}
            control={<Radio color="primary" />}
            label={<Typography variant="h6">You submitted:</Typography>}
          />
          <Box marginLeft={4}>
            <Typography>
              {`${submittedAddress?.orgAddressLine1 || ""}, ${
                submittedAddress?.orgAddressLocality || ""
              }, ${submittedAddress?.orgAddressRegion || ""} ${
                submittedAddress?.orgAddressPostalCode || ""
              }, United States` || EMPTY_SUBMITTED_ADDRESS_TEXT}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </RadioGroup>
  );
};
