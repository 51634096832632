import {
  TValidateTaskFromInitialValues,
  TVerificationUpdateData
} from "Shared/types";

import { transformAcceptingNewPatientToVariable } from "./transformNewPatient";
import { transformNumberToConcatString } from "./transformNumberToConcatString";
import { transformInitialValuesTOHoursOfOperation } from "./transformHoursOfOperationData";

/**
 * Transform from verification values to be ready from back-end;
 * some specific filed like: hoursOfOperationOrganization, hoursOfOperationDepartment and directFaxNumber
 * @param changedValues changed values from verification form
 */
export const transformFormDataToVerificationData = (
  changedValues: TValidateTaskFromInitialValues
): TVerificationUpdateData => {
  const {
    directFaxNumber,
    hoursOfOperationOrganization,
    hoursOfOperationDepartment,
    isAcceptNewPatients,
    directPhoneNumber,
    personalPhoneNumber,
    ...restData
  } = changedValues || {};

  const verificationUpdateData: TVerificationUpdateData = {
    ...restData
  };

  // transform and add directFaxNumber ready for back-end (removing "-" characters)
  if (directFaxNumber) {
    const transformedDirectFaxNumber =
      transformNumberToConcatString(directFaxNumber);

    verificationUpdateData.directFaxNumber = transformedDirectFaxNumber;
  }

  // transform and add directPhoneNumber ready for back-end (removing "-" characters)
  if (directPhoneNumber) {
    const transformedDirectPhoneNumber =
      transformNumberToConcatString(directPhoneNumber);

    verificationUpdateData.directPhoneNumber = transformedDirectPhoneNumber;
  }

  // transform and add directPhoneNumber ready for back-end (removing "-" characters)
  if (personalPhoneNumber) {
    const transformedPersonalPhoneNumber =
      transformNumberToConcatString(personalPhoneNumber);

    verificationUpdateData.personalPhoneNumber = transformedPersonalPhoneNumber;
  }

  if (isAcceptNewPatients) {
    verificationUpdateData.isAcceptNewPatients =
      transformAcceptingNewPatientToVariable(isAcceptNewPatients);
  }

  // combine Date Range Operation Hours from verification form in to back-end ready OperationHours filed
  if (hoursOfOperationOrganization || hoursOfOperationDepartment) {
    const transformedOperationHours = transformInitialValuesTOHoursOfOperation(
      hoursOfOperationDepartment,
      hoursOfOperationOrganization
    );

    // need to send empty value instead empty array
    verificationUpdateData.operationHours = transformedOperationHours?.length
      ? transformedOperationHours
      : undefined;
  }

  return verificationUpdateData;
};
