import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";

import { Theme, FormikTextField, getAddressString, Chip } from "Shared";
import {
  CallTaskSessionFragment,
  TaskAddress,
  UserFragment
} from "Shared/graphql/generated";

import { TopSectionSubmit } from "./TopSectionSubmit";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.ultraLight
  },
  cityIcon: {
    color: theme.palette.grey["500"]
  },
  addressTitle: {
    whiteSpace: "pre-line"
  }
}));

export type TopSectionProps = {
  taskId: string;
  name?: string | null;
  address?: TaskAddress;
  completedBy?: UserFragment | null;
  completed?: boolean;
  readonly?: boolean;
  orgType?: string;
  sessionId?: string;
  lastSession?: CallTaskSessionFragment | null;
  updated?: string;
};

const noteStyles = { backgroundColor: "#fff", borderRadius: "8px" };

export const TopSection = ({
  name,
  address,
  completedBy,
  taskId,
  sessionId,
  completed = false,
  readonly = false,
  orgType = "Unknown",
  lastSession,
  updated
}: TopSectionProps): JSX.Element => {
  const addressStr = getAddressString(address);
  const { container, cityIcon, addressTitle } = useStyles();

  return (
    <Box paddingY={5} className={container}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Typography variant="h4">{name}</Typography>
            <Chip label={orgType} />
            <Box mt={2} display="flex" maxWidth="240px">
              <Box mr={1}>
                <LocationCityIcon className={cityIcon} />
              </Box>
              <Typography className={addressTitle}>{addressStr}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              style={noteStyles}
              name="notes"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Notes"
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={4}>
            <TopSectionSubmit
              readonly={readonly}
              completed={completed}
              completedBy={completedBy}
              taskId={taskId}
              sessionId={sessionId}
              lastUpdate={lastSession?.lastUpdate}
              updated={updated}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
