import * as Yup from "yup";

export const orgIdInitialValues = { id: "", searchBy: "orgId" };

export const orgIdValidationSchema = Yup.object().shape({
  id: Yup.string()
    .required("Org ID required")
    .test("len", "Org ID must be digits", (val) => {
      const patter = new RegExp("^[0-9]+$");
      return !!val?.match(patter);
    })
});
