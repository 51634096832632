import { DID_NOT_CALL } from "Shared/constants";
import { TaskStatusReason } from "Shared/graphql/generated";
import { TFromPhoneCallStatus } from "Shared/types";

export const getStatusForCompleteVerification = (
  callStatus: TFromPhoneCallStatus
): TaskStatusReason | undefined => {
  if (callStatus === DID_NOT_CALL) {
    return undefined;
  }

  return callStatus;
};
