import { useCallback, useState } from "react";
import Cookies from "js-cookie";

import { useResponseHandler, useSnackBarMessage, CookiesValues } from "Shared";
import {
  ExchangeTokensInput,
  useExchangeTokenMutation
} from "Shared/graphql/generated";

export const useExchangeToken = () => {
  const [isExpired, setIsExpired] = useState(false);
  const { showSuccess, showError } = useSnackBarMessage();
  const { handleResponse } = useResponseHandler();

  const [exchangeTokenMutation, { loading }] = useExchangeTokenMutation({
    fetchPolicy: "no-cache"
  });

  const exchangeToken = useCallback(
    async (variables: ExchangeTokensInput) => {
      const res = await exchangeTokenMutation({ variables });
      const result = res.data?.exchangeToken;
      if (result) {
        const { response, isSuccess } = handleResponse(result);

        if (isSuccess) {
          showSuccess("Successfully logged in");
          setIsExpired(false);

          const {
            signedIn: { key, value }
          } = CookiesValues;

          Cookies.set(key, value);

          return response;
        } else {
          setIsExpired(true);
          showError(response.message);
        }
      }
    },
    [exchangeTokenMutation, handleResponse, showError, showSuccess]
  );

  return {
    exchangeToken,
    isLoading: loading,
    isExpired
  };
};
