import { Box, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  saveButtons: {
    display: "inline-flex",
    marginRight: 10,
    minWidth: "180px"
  }
}));

type OrganizationActionsButtonsProps = {
  isCompleted: boolean;
  onUpdate: () => Promise<void>;
  onSaveAndDefer: () => Promise<void>;
  onComplete: () => Promise<void>;
  isUpdateButtonEnabled: boolean;
  isSaveAndDeferEnabled: boolean;
  isCompleteButtonEnabled: boolean;
};

export const OrganizationActionsButtons = ({
  isCompleted,
  onUpdate,
  onSaveAndDefer,
  onComplete,
  isUpdateButtonEnabled,
  isSaveAndDeferEnabled,
  isCompleteButtonEnabled
}: OrganizationActionsButtonsProps) => {
  const { saveButtons } = useStyles();

  return (
    <Box>
      {isCompleted ? (
        <Button
          className={saveButtons}
          onClick={onUpdate}
          variant="contained"
          type="submit"
          color="primary"
          disabled={!isUpdateButtonEnabled}
        >
          Update
        </Button>
      ) : (
        <>
          <Button
            className={saveButtons}
            onClick={onSaveAndDefer}
            variant="contained"
            type="submit"
            disabled={!isSaveAndDeferEnabled}
          >
            Save and Defer
          </Button>
          <Button
            className={saveButtons}
            onClick={onComplete}
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isCompleteButtonEnabled}
          >
            Complete
          </Button>
        </>
      )}
    </Box>
  );
};
