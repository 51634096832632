import { Grid, Box, makeStyles } from "@material-ui/core";

import {
  FormikTextField,
  FormikAddHoursOfOperations,
  Fieldset,
  FormikAdressGroup
} from "Shared";
import {
  SectionContainer,
  AddOrgNpiAndTaxId
} from "AffiliationValidation/components";
import { OperationHourType } from "Shared/graphql/generated";

const useStyles = makeStyles({
  gridItem: {
    marginBottom: "8px"
  }
});

export const VerifyWorkOrganization: React.FC = () => {
  const { gridItem } = useStyles();

  return (
    <SectionContainer label="Verify work organization details:">
      <Box component={(props) => <Fieldset {...props} />}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={gridItem}>
            <FormikTextField
              required
              name="orgName"
              label="Organization Name"
            />
          </Grid>
          <FormikAdressGroup />
          <Grid item xs={12}>
            <AddOrgNpiAndTaxId />
          </Grid>
          <Grid item xs={12}>
            <FormikAddHoursOfOperations type={OperationHourType.Organization} />
          </Grid>
        </Grid>
      </Box>
    </SectionContainer>
  );
};
