import { TaskPhoneNumberFragment } from "Shared/graphql/generated";
import {
  DID_NOT_CALL,
  nullValuesToUndefined,
  TFromPhoneCallStatus,
  TFromPhoneNumber
} from "Shared";

/**
 * Get initial call status for TFromPhoneNumber
 */
export const getFormCallStatus = ({
  callStatus
}: TaskPhoneNumberFragment): TFromPhoneCallStatus => {
  if (callStatus) {
    return callStatus;
  }

  return DID_NOT_CALL;
};

/**
 * Transform back-end phoneNumbers array into TFromPhoneNumber[] initial values for verification form
 */
export const transformPhoneNumbersToInitialValues = (
  phoneNumbers?: TaskPhoneNumberFragment[]
): TFromPhoneNumber[] => {
  const initialPhoneNumbers =
    phoneNumbers?.map((phoneNumber) => {
      const formCallStatus = getFormCallStatus(phoneNumber);

      const { number, called, ...phoneNumberData } = phoneNumber;

      const transformedPhoneNumberData = nullValuesToUndefined(phoneNumberData);

      return {
        number,
        called,
        ...transformedPhoneNumberData,
        callStatus: formCallStatus
      };
    }) || [];

  return initialPhoneNumbers;
};
