import * as Yup from "yup";

import { isValueURL } from "Shared/utils";
import {
  phoneNumbersValidation,
  phoneNumberValidation
} from "Shared/utils/formikValidations";
import { zipCodeValidation } from "Shared/utils/formikValidations/zipCodeValidation";
import { hoursOfOperationValidation } from "Shared/utils/formikValidations/hoursOfOperationValidation";

export const orgTaskValidation = Yup.object().shape({
  domainName: Yup.string().test(
    "domain name",
    "Org (Domain) URL must be a valid URL. Example: https://www.h1.co",
    (value) => {
      if (value) {
        return isValueURL(value);
      }
      return true;
    }
  ),
  affiliationVerificationUrl: Yup.string().test(
    "affiliation verification url",
    "Affiliation Verification URL must be a valid URL. Example: https://www.h1.co",
    (value) => {
      if (value) {
        return isValueURL(value);
      }
      return true;
    }
  ),
  phoneNumbers: phoneNumbersValidation,
  orgFaxNumber: phoneNumberValidation({
    type: "correctLength",
    field: "Fax Number"
  }),
  affiliationVerificationUrlDetail: Yup.string().when(
    "affiliationVerificationUrl",
    {
      is: (url: string) => !url,
      then: Yup.string().length(
        0,
        "can only enter affiliation url details if affiliation url exists"
      ),
      otherwise: Yup.string()
    }
  ),
  orgAddressPostalCode: zipCodeValidation,
  hoursOfOperationOrganization: hoursOfOperationValidation(
    "Hours Of Operation Organization"
  )
});
