import { Form, Formik } from "formik";
import { Box } from "@material-ui/core";

import {
  GenericSearchComponent,
  FormikTextField,
  FormikAutocompleteSingle,
  SearchBy,
  ButtonOnNext
} from "Shared";
import { searchByOptions } from "Shared/constants";

import { orgIdInitialValues, orgIdValidationSchema } from "./orgIdHelpers";
import { useOrgIdSearch } from "./hooks/useOrgIdSearch";

export const OrgIdSearch = () => {
  const { onSubmit, isLoading } = useOrgIdSearch();

  return (
    <GenericSearchComponent label="Copy and paste the Organization ID corresponding to a task assigned to you.">
      <Formik
        onSubmit={onSubmit}
        initialValues={orgIdInitialValues}
        validationSchema={orgIdValidationSchema}
      >
        {({ values, isValid }) => (
          <Form>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="left" my={4}>
                <FormikAutocompleteSingle
                  label="Search by"
                  name="searchBy"
                  options={searchByOptions}
                  style={{ width: 150 }}
                  disableClearable
                />
                <FormikTextField
                  name="id"
                  label={values.searchBy === SearchBy.ORG_ID ? "ID" : "NPI"}
                  style={{ marginLeft: 15 }}
                />
              </Box>
              <Box width={130}>
                <ButtonOnNext disabled={!isValid || isLoading} />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </GenericSearchComponent>
  );
};
