import { AddressFragment, TaskAddress } from "Shared/graphql/generated";

export const getAddressString = (address?: AddressFragment | TaskAddress) => {
  if (address) {
    // TODO: remove if ("postalCode" in address) check after move to initial data for all pages
    if ("postalCode" in address) {
      const { line1, locality, region, postalCode } = address;

      const line2 = `${locality || ""}, ${region || ""} ${postalCode || ""}`;

      return (line1 || "") + "\n" + line2;
    } else {
      //TODO remove after move to initial data for all pages
      const { line1, city, state, zip } = address;

      const line2 = `${city || ""}, ${state || ""} ${zip || ""}`;

      return (line1 || "") + "\n" + line2;
    }
  }

  return "No address";
};
