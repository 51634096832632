import { Container, Typography, Grid, Box } from "@material-ui/core";

import { PersonTaskData } from "Shared/graphql/generated";

import { InfoItem } from "../components";
import { transformCorrectPhone } from "../helpers";

const getArrayStr = (arr: string[], field: string) => {
  if (arr.length > 1) {
    return arr.join(", ");
  }
  if (arr.length === 1) {
    return arr[0];
  }
  return `No ${field}`;
};

export const PersonDetails = ({
  firstName,
  lastName,
  npi,
  phone,
  specialties,
  designations
}: PersonTaskData) => {
  const fullName = `${firstName || ""} ${lastName || ""}`;
  const specialtiesStr = getArrayStr(specialties, "specialties");
  const designationsStr = getArrayStr(designations, "designations");

  return (
    <Container component={Box} paddingY={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">{fullName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InfoItem label="NPI" content={npi || "No NPI"} />
        </Grid>
        <Grid item xs={6}>
          <InfoItem
            label="Phone Number"
            content={transformCorrectPhone(phone?.number || "No phone numbers")}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoItem label="Specialities" content={specialtiesStr} />
        </Grid>
        <Grid item xs={6}>
          <InfoItem
            label="Designation(s)"
            content={designationsStr || "No designations"}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
