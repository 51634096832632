import * as Yup from "yup";

import {
  phoneNumberValidation,
  urlValidation,
  phoneNumbersValidation
} from "Shared/utils";
import { DateRangeValues } from "Shared";
import { zipCodeValidation } from "Shared/utils/formikValidations/zipCodeValidation";
import { hoursOfOperationValidation } from "Shared/utils/formikValidations/hoursOfOperationValidation";

const ONLY_POSITIVE_DIGITS_REGEX = /^\d+$/;

export const affiliationFromValidation = Yup.object().shape({
  directFaxNumber: phoneNumberValidation({
    type: "correctFaxNumber",
    field: "Direct Fax Number"
  }),
  orgNpi: Yup.string()
    .length(10, "Org NPI must be 10 digits")
    .matches(ONLY_POSITIVE_DIGITS_REGEX, "Org NPI must be 10 digits"),

  orgTaxId: Yup.string()
    .length(9, "Tax ID must be 9 digits")
    .matches(ONLY_POSITIVE_DIGITS_REGEX, "Tax ID must be 9 digits"),
  email: Yup.string().email("Please enter a valid email address."),
  orgName: Yup.string().required("Organization Name is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phoneNumbers: phoneNumbersValidation,
  affiliationStatus: Yup.string().required("Affiliation Status is required"),
  profileUrl: urlValidation({ type: "profileUrl", field: "Profile Url" }),
  listingUrl: urlValidation({ type: "listingUrl", field: "Listing Url" }),
  department: Yup.string().when("hoursOfOperationDepartment", {
    is: (hours: DateRangeValues[]) => hours?.length,
    then: Yup.string().required("Department is required"),
    otherwise: Yup.string()
  }),
  appointmentPhoneNumber: phoneNumberValidation({
    type: "correctLength",
    field: "Appointment Phone"
  }).when("appointmentPhoneNumberExt", {
    is: (val: string) => val,
    then: Yup.string().required("Appointment Phone is required"),
    otherwise: Yup.string()
  }),
  appointmentPhoneNumberExt: Yup.string().matches(
    /^[0-9]+$/,
    "Must be only digits"
  ),
  orgAddressPostalCode: zipCodeValidation,
  hoursOfOperationDepartment: hoursOfOperationValidation(
    "Hours Of Operation Department"
  ),
  hoursOfOperationOrganization: hoursOfOperationValidation(
    "Hours Of Operation Organization"
  )
});
