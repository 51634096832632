import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  saveButtons: {
    display: "inline-flex",
    marginRight: 10,
    minWidth: "100px"
  }
}));

type TopSectionActionButtonsProps = {
  completed: boolean;
  isSubmitDisabled: boolean;
  isSaveAndDeferDisabled: boolean;
  onSaveAndDefer: () => Promise<void>;
  onComplete: () => Promise<void>;
  onUpdate: () => Promise<void>;
};

export const TopSectionActionButtons: React.FC<
  TopSectionActionButtonsProps
> = ({
  isSubmitDisabled,
  isSaveAndDeferDisabled,
  completed,
  onSaveAndDefer,
  onComplete,
  onUpdate
}) => {
  const { saveButtons } = useStyles();
  return (
    <>
      {!completed && (
        <Button
          className={saveButtons}
          onClick={onSaveAndDefer}
          variant="contained"
          type="submit"
          disabled={isSaveAndDeferDisabled}
        >
          Save And Defer
        </Button>
      )}
      <Button
        className={saveButtons}
        disabled={isSubmitDisabled}
        variant="contained"
        type="submit"
        color="primary"
        onClick={completed ? onUpdate : onComplete}
      >
        {completed ? "Update" : "Complete"}
      </Button>
    </>
  );
};
