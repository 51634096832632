import omit from "lodash/omit";

import { listExcludedFormFields } from "Shared";
import { UpdateAffiliationCallSessionInput } from "Shared/graphql/generated";
import { TValidateTaskFromInitialValues } from "Shared/types";

import { getUpdateData } from "./getUpdatedData";

export type TransformTaskValuesToUpdateVariablesInput = {
  newFormValues: TValidateTaskFromInitialValues;
  initFormValues: TValidateTaskFromInitialValues;
  sessionId?: string;
  isAutocompleted: boolean;
};
/**
 * This function find the diff between initial form values and new form values
 * and returns variables(input) for UpdateAffiliationCallSessionInput mutation
 */
export const transformTaskValuesToUpdateVariables = ({
  newFormValues,
  initFormValues,
  sessionId = "",
  isAutocompleted
}: TransformTaskValuesToUpdateVariablesInput): UpdateAffiliationCallSessionInput => {
  // convert keys with value = `""` to undefined when the value was previously undefined
  const { updateData } = getUpdateData(
    omit(newFormValues, listExcludedFormFields),
    initFormValues
  );

  return {
    id: sessionId,
    update: { ...omit(updateData, "notes"), isAutocompleted }
  };
};
