import { useCallback } from "react";
import { useNavigate } from "react-router";

import { generateRoutePath, RouteName } from "App";
import {
  FindOrganizationTaskQuery,
  useFindOrganizationTaskLazyQuery
} from "Shared/graphql/generated";

export const useOrgIdSearch = () => {
  const navigate = useNavigate();

  const [getOrgTasks, { loading }] = useFindOrganizationTaskLazyQuery({
    onCompleted: (data: FindOrganizationTaskQuery) => {
      const orgId = data.findOrganizationTask?.orgMasterId;

      if (orgId) {
        navigate(
          generateRoutePath({
            name: RouteName.OrganizationValidationWithParams,
            params: { orgId }
          })
        );
      }
    }
  });

  const onSubmit = useCallback(
    async ({ id }: { id: string }) => {
      await getOrgTasks({ variables: { orgId: id } });
    },
    [getOrgTasks]
  );

  return {
    onSubmit,
    isLoading: loading
  };
};
