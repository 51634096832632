import { useCallback, useState } from "react";

import {
  TFormUpdateModalModes,
  useAddressValidation,
  useModal,
  SubmitAddressOption,
  useIsAutocompletedAddressFeatureEnabled
} from "Shared";

// TODO: simplify this logic
export const useAddressValidationBeforeSubmit = ({
  onUpdate,
  onSaveAndDefer,
  onComplete
}: {
  onUpdate: () => Promise<void>;
  onSaveAndDefer: () => Promise<void>;
  onComplete: () => void;
}) => {
  const [selectedSubmitAddressOption, setSelectedSubmitAddressOption] =
    useState<SubmitAddressOption | null>(null);
  const isAutocompletedAddressFeatureEnabled =
    useIsAutocompletedAddressFeatureEnabled();
  const [formUpdateModalMode, setFormUpdateModalMode] =
    useState<TFormUpdateModalModes | null>(null);

  const { validateAddress, validationAddressData } = useAddressValidation();

  const {
    isOpen: isOpenValidation,
    closeModal: closeModalValidation,
    openModal: openModalValidation
  } = useModal();
  const onValidationModalClose = useCallback(() => {
    setSelectedSubmitAddressOption(null);
    closeModalValidation();
  }, [closeModalValidation, setSelectedSubmitAddressOption]);

  const onOpenModalAction = useCallback(
    async (action: () => Promise<void> | void) => {
      if (isAutocompletedAddressFeatureEnabled) {
        const validated = await validateAddress();

        if (validated) {
          await action();
          return;
        }

        openModalValidation();
      } else {
        await action();
      }
    },
    [isAutocompletedAddressFeatureEnabled, validateAddress, openModalValidation]
  );

  const onOpenModalUpdate = async () => {
    setFormUpdateModalMode(TFormUpdateModalModes.UPDATE);
    await onOpenModalAction(onUpdate);
  };

  const onOpenModalSaveAndDefer = async () => {
    setFormUpdateModalMode(TFormUpdateModalModes.SAVE_AND_DEFER);
    await onOpenModalAction(onSaveAndDefer);
  };

  const onOpenModalComplete = async () => {
    setFormUpdateModalMode(TFormUpdateModalModes.COMPLETE);
    await onOpenModalAction(onComplete);
  };

  return {
    onOpenModalUpdate,
    onOpenModalSaveAndDefer,
    onOpenModalComplete,
    formUpdateModalMode,
    closeModalValidation: onValidationModalClose,
    openModalValidation,
    isOpenValidation,
    selectedSubmitAddressOption,
    validationAddressData,
    isAutocompletedAddressFeatureEnabled
  };
};
