import { Box, makeStyles } from "@material-ui/core";
import React, { memo } from "react";

import { Theme } from "../../../theme";

import { DAYS_OF_THE_WEEK } from "./helpers";

const useStyles = makeStyles((theme: Theme) => ({
  dayBox: {
    backgroundColor: theme.palette.grey[750],
    color: "white",
    cursor: "pointer",
    margin: "1px",
    padding: "3%",
    textAlign: "center",
    flex: 1,
    "&:hover": {
      opacity: "0.5"
    }
  },
  readOnly: {
    opacity: "0.5"
  },
  active: {
    backgroundColor: theme.palette.activeColors.blueBackground2
  }
}));

export type DatesRangeProps = {
  values: boolean[];
  readonly: boolean | undefined;
  onClick: (fieldValue: number) => void;
};

export const DatesRange = memo(
  ({ values, readonly, onClick }: DatesRangeProps) => {
    const { dayBox, active, readOnly } = useStyles();
    return (
      <Box display="inline-flex" flexDirection="row" flex={6}>
        {DAYS_OF_THE_WEEK.map(({ label, index }) => (
          <Box
            className={`${dayBox} ${readonly ? readOnly : ""} ${
              values[index] ? ` ${active}` : ""
            }`}
            onClick={() => !readonly && onClick(index)}
            key={`${label} ${index}`}
          >
            {label}
          </Box>
        ))}
      </Box>
    );
  }
);
