import orderBy from "lodash/orderBy";
import { useCallback, useMemo } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import {
  getReactNodeWithHighlightedText,
  ESortDirection,
  useFormikField,
  TAutocomplete,
  TAddressOption
} from "Shared";

import { FormikAutocompleteAddressComponent } from "./index";

export type FormikAutocompleteAddressProps = Omit<
  TAutocomplete,
  "handleChange" | "onBlur" | "autocomplete" | "autocompleteValue" | "multiple"
> & {
  onAddressSelect?: (address: TAddressOption) => void;
};

export const FormikAutocompleteAddress = (
  props: FormikAutocompleteAddressProps
) => {
  const { options, sort, name } = props;
  const formikPassedProps = useFormikField<string>(name);

  const sortedOptions = useMemo(
    () =>
      orderBy(
        options,
        [(option) => (sort ? sort(option) : option.label)],
        ESortDirection.ASC
      ),
    [options, sort]
  );

  const renderOption = useCallback(
    (option: TAddressOption) => (
      <>
        <LocationOnIcon />
        {getReactNodeWithHighlightedText(option)}
      </>
    ),
    []
  );

  const passedProps = {
    ...props,
    options: sortedOptions,
    renderOption
  };

  return (
    <FormikAutocompleteAddressComponent
      {...formikPassedProps}
      {...passedProps}
    />
  );
};
