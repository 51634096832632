import { Box, CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";

import {
  FormikTextField,
  useSetDocumentTitle,
  GenericSearchComponent,
  ButtonOnNext,
  PageProps
} from "Shared";

import { useNpiSearch } from "./hooks/useNpiSearch";
import { npiInitialValues, npiValidationSchema } from "./npiHelpers";

export const NPISearch = ({ title }: PageProps) => {
  useSetDocumentTitle(title);

  const { isLoading, onSubmit } = useNpiSearch();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexGrow={1}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GenericSearchComponent label="Copy and paste the NPI corresponding to a task assigned to you.">
      <Formik
        onSubmit={onSubmit}
        initialValues={npiInitialValues}
        validationSchema={npiValidationSchema}
      >
        {({ isValid }) => (
          <Form>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box width={200} my={4}>
                <FormikTextField name="npi" label="Person NPI" />
              </Box>
              <ButtonOnNext disabled={!isValid || isLoading} />
            </Box>
          </Form>
        )}
      </Formik>
    </GenericSearchComponent>
  );
};
