import { useMemo } from "react";

import { usePersonAffiliationTasksQuery } from "Shared/graphql/generated";

export const useGetPersonAffiliationTasks = (npi: string) => {
  const { loading, data } = usePersonAffiliationTasksQuery({
    variables: { npi }
  });

  const tasks = useMemo(() => {
    return data?.personAffiliationTasks || [];
  }, [data]);

  const person = useMemo(() => {
    return tasks[0]?.initialData.person;
  }, [tasks]);

  return {
    isLoading: loading,
    person,
    tasks
  };
};
